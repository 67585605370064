import React from "react";
import "./progress-circle.css";

export default function ProgressCircle() {
  return (
    <div className="progress-circle__container">
      <div className="spinner spinner-muted spinner-lg progress-circle__spinner" />
    </div>
  );
}

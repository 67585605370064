import React, { useState, useEffect } from "react";
import { FormGroup } from "reactstrap";
import Cropper from "react-easy-crop";
import { ImageSelectButton, ImageSaveButton } from "./imageButtons";
import { getCroppedPng, imageToUrl, getPlatformAspectRatio } from "./utils";

const ImageSelector = ({ imageUrl, platform, onNewImage, onError }) => {
  // Saves change state
  const [pendingCropChanges, setPendingCropChanges] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);

  // Loaded image URL
  const [image, setImage] = useState(imageUrl);

  // Current crop state
  const [crop, onCropChange] = React.useState({ x: 0, y: 0 });
  const [zoom, onZoomChange] = React.useState(1);

  // Crop state before editing the picture
  const [initialCroppedAreaPixels, setInitialCroppedAreaPixels] = useState(null);
  // Crop state after the cropping is finished
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Check if changes have been made and set `pendingChanges`
  useEffect(() => {
    // If no initialCroppedAreaPixels are set, set them
    if (!initialCroppedAreaPixels) {
      setInitialCroppedAreaPixels(croppedAreaPixels);
    }

    // If both exist, compare them and check for changes
    if (croppedAreaPixels && initialCroppedAreaPixels) {
      if (
        croppedAreaPixels.x === initialCroppedAreaPixels.x &&
        croppedAreaPixels.y === initialCroppedAreaPixels.y &&
        croppedAreaPixels.width === initialCroppedAreaPixels.width &&
        croppedAreaPixels.height === initialCroppedAreaPixels.height
      ) {
        // Doesn't match initial crop data, hence changes have been made
        setPendingCropChanges(false);
      } else {
        setPendingCropChanges(true);
      }
    } else {
      setPendingCropChanges(false);
    }
  }, [croppedAreaPixels, initialCroppedAreaPixels]);

  // Renders and propagates the new image to the editor form
  const onApplyChanges = async () => {
    try {
      const croppedImage = await getCroppedPng(image, croppedAreaPixels);
      // Callback the new image blob
      onNewImage(croppedImage);

      // Set current crop state as initial since we've saved the changes
      setInitialCroppedAreaPixels(croppedAreaPixels);
    } catch (e) {
      onError(e);
      console.error(e);
    }
  };

  // Load new user selected image
  const loadImage = async images => {
    // No file selected
    if (!images.length) return;

    const imageDataUrl = await imageToUrl(images[0]);

    // Set as active image URL
    setImage(imageDataUrl);

    // Mark the image change state as true
    setImageChanged(true);
  };

  return (
    <FormGroup style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "17px",
          letterSpacing: "0.04em",
          color: "#6C757D",
          margin: "20px 0px",
          textTransform: "capitalize",
        }}
      >
        {`${platform} image selection`}
      </span>

      <div
        style={{
          width: "700px",
          height: "500px",
          background: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={getPlatformAspectRatio(platform)}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={(_, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels);
          }}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <ImageSelectButton onDrop={loadImage} />
        <ImageSaveButton isDisabled={!pendingCropChanges && !imageChanged} onClick={onApplyChanges} />
      </div>
    </FormGroup>
  );
};

export default ImageSelector;

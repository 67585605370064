import { connectFetch, convertQueryToUri } from "./utils";

const getAdsByCampaignId = async (token, campaignId, pageSize = 1000) => {
  const uri = await convertQueryToUri("/", {
    filter: { campaignId },
    page: 0,
    pageSize,
    order: {},
  });

  return connectFetch("ad", token, uri);
};

const updateAds = async (token, ads) => {
  const tasks = ads.map(async ad =>
    connectFetch("ad", token, `/${ad._id}`, { method: "PUT", body: JSON.stringify(ad) })
  );

  return Promise.all(tasks);
};

export { getAdsByCampaignId, updateAds };

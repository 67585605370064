import React from "react";
import { Route, Switch } from "react-router-dom";
import CampaignDisplay from "../campaign-display";
import CampaignDetails from "../campaign-details";
import AdEditor from "../ad-editor";
import { CampaignsContextProvider } from "../../../contexts/campaignsContext";
import { EditorContextProvider } from "../../../contexts/editorContext";

export function CampaignManager() {
  return (
    <CampaignsContextProvider>
      <EditorContextProvider>
        <div className="container-fluid" style={{ minHeight: "0px" }}>
          <div className="row h-100 align-self-md-center" style={{ minHeight: "0px" }}>
            <Switch>
              <Route exact path="/:campaignType/:campaignID/edit" render={() => <AdEditor />} />
              <Route path="/:campaignType" render={() => <CampaignDisplay />} />
            </Switch>
            <Route render={() => <CampaignDetails />} />
          </div>
        </div>
      </EditorContextProvider>
    </CampaignsContextProvider>
  );
}

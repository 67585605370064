import { connectFetch, connectApiWrapperHook } from "./utils";

const uploadImageAsset = async (token, customerId, imageFile) => {
  const uri = "/images";

  const formData = new FormData();
  formData.append("customerId", customerId);
  formData.append("category", "uploads");
  formData.append("image", imageFile);

  return connectFetch("asset", token, uri, { method: "POST", body: formData }, true);
};

const useUploadImageAsset = connectApiWrapperHook(uploadImageAsset);

export { uploadImageAsset, useUploadImageAsset };

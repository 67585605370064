import { connectFetch, connectApiWrapperHook, convertQueryToUri } from "./utils";

const getCustomerNames = async (token, customerIds) => {
  const uri = await convertQueryToUri("/", {
    filter: customerIds ? { _id: { $in: customerIds } } : null,
    page: 0,
    pageSize: 1000,
    order: {},
  });

  return connectFetch("customer", token, uri);
};

const useCustomerData = connectApiWrapperHook(getCustomerNames);

export { useCustomerData, getCustomerNames };

import Color from "color";
import React, { FC } from "react";
import { Audience, Category } from "./interfaces";

interface AudienceDataProps {
  audiencesUnder1000: Audience[];
  audiences: Category[];
  selectedIndex?: number;
}

const AudienceData: FC<AudienceDataProps> = ({ audiences, audiencesUnder1000, selectedIndex }) => {
  const renderSmallAudience = (name: string, value: number, index: number) => (
    <li className="br-bottom" key={`${name}_${value}_${index}`}>
      <div className="row align-items-center">
        <div className="col-1">
          <span className="bullet">&#x2022;</span>
        </div>
        <div className="col-10">
          <span className="item-text">{name}</span>
        </div>
      </div>
    </li>
  );
  return (
    <>
      <div className="row mt-5 ml-5 gx-5">
        {audiences.map(({ name, value, color }, index) => (
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4" key={`${name}_${value}_${index}`}>
            <div
              className="wrapper"
              style={{
                background: selectedIndex === index ? Color(color).alpha(0.15).toString() : "",
              }}
            >
              <div
                className="left-border"
                style={{
                  background: color,
                }}
              ></div>
              <div className="content">
                <div className="text-left p-2">
                  <p className="mb-0 ml-2">{name}</p>
                  <p className="mb-0 ml-2">{value}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {audiencesUnder1000.length > 0 && (
        <div className="row mt-5 ml-5 text-left opacity-80">
          <h4>Below 1000</h4>
          <p className="mt-2">
            These Social Talent Pools{String.fromCodePoint(0x02122)} contain less than 1,000 individuals. Please talk to
            your Customer Success Manager to see what action can be taken to optimize your advertising.
          </p>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <ul className="list-unstyled">
                  {audiencesUnder1000
                    .slice(0, Math.ceil(audiencesUnder1000.length / 2))
                    .map(({ name, value }, index) => renderSmallAudience(name, value, index))}
                </ul>
              </div>
              <div className="col-6">
                <ul className="list-unstyled" style={{}}>
                  {audiencesUnder1000
                    .slice(Math.ceil(audiencesUnder1000.length / 2))
                    .map(({ name, value }, index) => renderSmallAudience(name, value, index))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AudienceData;

import React, { useState, createContext, useEffect, useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { getCampaignFeed, getCampaignById, getAdsByCampaignId, getFilters } from "../connect-api";
import { getAggregatedAnalytics } from "../utils/index";

import { DashboardContext } from "./dashboardContext";

// Infinite scroll constants
const CAMPAIGNS_PER_FEED_PAGE = 5;

// Convert filters, search and cmapaign type selection into a DB filter
const convertStateFiltertoDbFilter = (searchQuery, typeFilter, stateFilters, customerId) => {
  const apiFilters = {
    jobPostId: { $exists: true },
  };

  const migrateFilter = (apiField, filters) => {
    Object.values(filters).forEach(option => {
      if (option.active) {
        if (!apiFilters[apiField]) {
          apiFilters[apiField] = { $in: [] };
        }
        apiFilters[apiField].$in.push(option.name);
      }
    });
  };

  // Filter by customerId
  if (customerId) apiFilters["customerId"] = customerId;

  if (stateFilters) {
    migrateFilter("status", stateFilters.status);
    migrateFilter("categories", stateFilters.category);
    migrateFilter("locations.city", stateFilters.location);

    if (typeFilter) {
      if (typeFilter === "jobs") apiFilters["jobPostId"] = { $exists: true };
      else apiFilters["jobPostId"] = { $exists: false };
    }
  }

  if (!stateFilters && typeFilter !== "jobs") {
    apiFilters["jobPostId"] = { $exists: false };
  }

  if (searchQuery) {
    apiFilters["$or"] = [
      {
        title: {
          $regex: `${searchQuery}`,
          $options: "i",
        },
      },
      {
        atsJobPostId: {
          $regex: `${searchQuery}`,
          $options: "i",
        },
      },
    ];
  }

  if (Object.keys(apiFilters).length) {
    const dbQuery = { $and: [] };
    Object.entries(apiFilters).forEach(([key, value]) => {
      dbQuery.$and.push({ [key]: value });
    });

    return dbQuery;
  }

  return null;
};

// Generate the base filter options state based on the available filters
const generateFilterOptions = availableFilters => {
  const filterOptions = { category: {}, location: {}, status: {} };

  if (!availableFilters) return null;

  const setFilters = (filterName, options) => {
    if (options)
      options.forEach(option => {
        filterOptions[filterName][option.toLowerCase()] = {
          name: option,
          active: false,
        };
      });
  };

  setFilters("category", availableFilters.categories);
  setFilters("location", availableFilters.locations);
  setFilters("status", availableFilters.statuses);

  return filterOptions;
};

export const CampaignsContext = createContext({
  campaignList: null,
  selectedCampaignId: null,
  setSelectedCampaignId: null,
  filter: null,
  setFilter: null,
  loadMoreCampaigns: null,
  campaignsState: null,
  searchQuery: null,
  setSearchQuery: null,
  updateCampaignData: null,
});

// Store the API state in a singleton
const apiState = {
  lastCampaignType: null,
  apiCallInProgress: false,
};

export const CampaignsContextProvider = ({ children }) => {
  const { currentCustomer } = useContext(DashboardContext);
  const { getAccessTokenSilently } = useAuth0();
  const [campaignList, setCampaignList] = useState(null);
  const [campaignsState, setCampaignsState] = useState(null);
  const [targetLoadedCampaignPages, setTargetLoadedCampaignPages] = useState(1);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [filter, setFilter] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [allCampaignsAreLoaded, setAllCampaignsAreLoaded] = useState(false);
  const [pendingCampaignUpdates, setPendingCampaignUpdates] = useState([]);

  // Match the campaign type filter via the URI
  const { params } = useRouteMatch("/:campaignType");
  const { campaignType } = params;

  const loadMoreCampaigns = () => {
    // Don't load if loading is in progress or we have already loaded everything
    // Otherwise increment the target loaded pages and let the hook handle the rest
    if (!allCampaignsAreLoaded && campaignsState !== "loading") {
      setTargetLoadedCampaignPages(targetLoadedCampaignPages + 1);
    }
  };

  // Reset campaign data prior to filter/searchQuery changes
  const resetCampaigns = () => {
    setTargetLoadedCampaignPages(1);
    setCampaignList(null);
    setAllCampaignsAreLoaded(false);
  };

  const updateCampaignData = campaignId => {
    setPendingCampaignUpdates([...pendingCampaignUpdates, campaignId]);
  };

  // Load available filters
  useEffect(() => {
    const loadAvailableFilters = async () => {
      const token = await getAccessTokenSilently();
      const resp = await getFilters(token, currentCustomer);

      if (resp.ok) {
        const newAvailableFilters = await resp.json();

        setFilter(generateFilterOptions(newAvailableFilters));
      }
    };

    if (currentCustomer) loadAvailableFilters();
  }, [currentCustomer, getAccessTokenSilently]);

  useEffect(() => {
    // Handle state updates
    const listingNeedsUpdate = () => {
      // Reset if the campaign type filter has changed
      if (apiState.lastCampaignType !== campaignType) resetCampaigns();
      apiState.lastCampaignType = campaignType;

      // Check if an API call is already pending and block further operations
      if (apiState.apiCallInProgress) return false;

      // Don't run if all the remote campaigns are loaded, or the targetLoadedCampaignPages requirement is satisified
      if (
        campaignList &&
        (allCampaignsAreLoaded || campaignList.length === targetLoadedCampaignPages * CAMPAIGNS_PER_FEED_PAGE)
      )
        return;

      return true;
    };

    const campaignsNeedsUpdates = () => {
      // Check if an API call is already pending and block further operations
      if (apiState.apiCallInProgress) return null;

      if (pendingCampaignUpdates.length === 0) return null;

      const nextCampaignId = pendingCampaignUpdates.pop();
      setPendingCampaignUpdates(pendingCampaignUpdates);

      return nextCampaignId;
    };

    async function updateCampaign(campaignId) {
      // Block other API calls
      apiState.apiCallInProgress = true;

      const token = await getAccessTokenSilently();

      // Get updated campaign data
      const [updatedCampaignData, campaignAds] = await Promise.all([
        // @TODO error handling?
        getCampaignById(token, campaignId).then(res => res.json()),
        getAdsByCampaignId(token, campaignId).then(res => res.json()),
      ]);

      updatedCampaignData.ads = campaignAds;
      updatedCampaignData.aggregatedAnalytics = getAggregatedAnalytics(campaignAds);

      // Apply the update to the list
      const currentIndex = campaignList.findIndex(campaign => campaign._id === campaignId);
      campaignList[currentIndex] = updatedCampaignData;
      setCampaignList([...campaignList]);

      // Unlock other API calls
      apiState.apiCallInProgress = false;
    }

    async function updateCampaignList() {
      // Block other API calls
      apiState.apiCallInProgress = true;

      const token = await getAccessTokenSilently();

      // Mark as loading
      setCampaignsState("loading");

      // Generate a DB query filter
      const dbFilter = convertStateFiltertoDbFilter(searchQuery, campaignType, filter, currentCustomer);

      // Request another batch of data with proper sorting setup
      const sortOrder = {
        priority: -1,
        "schedule.start": -1,
        _id: -1,
      };

      const data = await getCampaignFeed(
        token,
        targetLoadedCampaignPages - 1, // Page indexing starts at 0 but targetLoadedCampaignPages starts at 1
        CAMPAIGNS_PER_FEED_PAGE,
        dbFilter,
        sortOrder
      );
      // If our request fails, don't try to append invalid data to the campaign list
      if (data.ok) {
        const campaignsBatch = data.data;

        // If the received batch is smaller than the requested amount
        // It means we've reached the end of available remote campaigns
        if (campaignsBatch.length < CAMPAIGNS_PER_FEED_PAGE) {
          setAllCampaignsAreLoaded(true);
        }

        // Append the batch to the loaded campaigns
        const newCampaignList = [...(campaignList || []), ...campaignsBatch];
        setCampaignList(newCampaignList);
      }

      // Mark as loaded
      apiState.apiCallInProgress = false;
      setCampaignsState("loaded");
    }

    // Make sure a selected `customerId` is available before doing anything
    if (!currentCustomer) {
      setCampaignsState("loading");
      return;
    }

    // Handle specific campaign update requests
    const campaignToUpdate = campaignsNeedsUpdates();
    if (campaignToUpdate) updateCampaign(campaignToUpdate);

    // Handle the state updated and conditionally fetch the new campaigns
    if (listingNeedsUpdate()) updateCampaignList();
  }, [
    filter,
    targetLoadedCampaignPages,
    getAccessTokenSilently,
    campaignList,
    allCampaignsAreLoaded,
    searchQuery,
    campaignType,
    currentCustomer,
    campaignsState,
    pendingCampaignUpdates,
  ]);

  return (
    <CampaignsContext.Provider
      value={{
        campaignList,
        selectedCampaignId,
        setSelectedCampaignId,
        filter,
        setFilter: filter => {
          setFilter({ ...filter });
          resetCampaigns();
        },
        loadMoreCampaigns,
        campaignsState,
        searchQuery,
        setSearchQuery: query => {
          setSearchQuery(query);
          resetCampaigns();
        },
        updateCampaignData,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

import React, { FC, Fragment } from "react";
import { separateThousands } from "./helpers";

interface Props {
  label: string;
  keyName?: string;
  reach?: Record<string, any>;
  overRideReachValue?: number;
  overRideCurrentReach?: number;
  currentReach?: Record<string, any>;
}

const Reach: FC<Props> = ({ reach = {}, keyName = "", label, overRideReachValue, currentReach = {} }) => (
  <Fragment>
    <h2>{separateThousands(overRideReachValue || currentReach[keyName])}</h2>
    <p className="mb-0 text-op-75">{label}</p>
    {JSON.stringify(reach) !== "{}" && (
      <Fragment>
        <span className="badge badge-success">{`+${separateThousands(overRideReachValue || reach[keyName])}`}</span>{" "}
        <small className="text-op-75">During last week</small>
      </Fragment>
    )}
  </Fragment>
);

export default Reach;

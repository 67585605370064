import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Sidebar from "../../components/sidebar";

import { DashboardContextProvider } from "../../../contexts/dashboardContext";
import { CampaignManager } from "../../components/campaign-manager";
import Insights from "../../components/insights";
import "./dashboard.css";

export default function Dashboard() {
  return (
    <DashboardContextProvider>
      <div className="d-flex flex-row h-100" style={{ width: "100%" }}>
        <Route render={() => <Sidebar />} />
        <Switch>
          <Route path="/insights" render={() => <Insights />} />
          <Route path="/jobs" render={() => <CampaignManager />} />
          <Route path="/branding" render={() => <CampaignManager />} />
          <Route path="/">
            <Redirect to="/jobs" />
          </Route>
        </Switch>
      </div>
    </DashboardContextProvider>
  );
}

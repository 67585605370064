import React, { FC, Fragment } from "react";
import styled from "styled-components";

import IonIcon from "../ionicon";

interface Props {
  ad: {
    platform: {
      type: string;
      creative: Record<string, any>;
      creatives: Record<string, any>;
    };
    adType: string;
  };
  adId: string;
}

const RenderSocialLink: FC<Props> = ({ ad: { platform, adType }, adId }) => {
  const { creative, creatives, type } = platform;
  const adTypes: Record<string, any> = {
    "facebook-remarketing": "Remarketing",
    "facebook-awareness": "Awareness",
    "linkedin-awareness": "Awareness",
    "snapchat-awareness": "Awareness",
    "snapchat-remarketing": "Remarketing",
  };
  const ads = creative
    ? [{ ...(creative || {}), platform: type }]
    : Object.keys(creatives || {}).map(key => ({ ...creatives[key], platform: key }));

  return (
    <Fragment>
      {ads.map(ad =>
        ad.adUrl ? (
          <a href={ad.adUrl} target="_blank" rel="noreferrer" key={ad.platform}>
            <button
              className="btn btn-block btn-sm btn-outline-secondary p-2 px-3 d-flex flex-row align-items-center text-left"
              style={{ marginTop: "7.2px" }}
              key={adId}
            >
              <div className="pr-3">
                <Icon name={`logo-${ad.platform}`} />
                <Icon name="git-compare" />
              </div>
              <div>
                <strong style={{ textTransform: "capitalize" }}>{ad.platform}</strong>
                <br />
                <small style={{ textTransform: "capitalize" }}>{adTypes[adType]}</small>
              </div>
            </button>
          </a>
        ) : null
      )}
    </Fragment>
  );
};

const Icon = styled(IonIcon)`
  margin-left: 14.4px;
`;

export default RenderSocialLink;

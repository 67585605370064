import React, { FC } from "react";
import { Cell, Label, Pie, PieChart as ChartContainer } from "recharts";
import { separateThousands } from "./helpers";

interface Props {
  data: {
    name: string;
    value: number;
    color: string;
  }[];
  content: "placeholder" | "label";
  customerData: any;
  onCellHover: any;
}

const PieChart: FC<Props> = ({ data, content, customerData, onCellHover }) => {
  const { facebookAudienceSize, weeklyAudienceDelta = { count: 0 } } = customerData;
  const audienceSize = facebookAudienceSize || 0;

  const renderLabel = {
    label: () => (
      <foreignObject x="90" y="143" width="200" height="100">
        <h1 className="mb-0">{separateThousands(audienceSize, false)}</h1>
        <p className="mt-1 text-op-75">People in your social talent pool</p>
        {weeklyAudienceDelta.count > 0 && (
          <>
            <span className="badge badge-success" style={{ marginLeft: "14.4px" }}>{`+${separateThousands(
              weeklyAudienceDelta.count
            )}`}</span>
            <small className="text-op-75" style={{ marginLeft: "5px" }}>
              Since last week
            </small>
          </>
        )}
      </foreignObject>
    ),
    placeholder: () => (
      <foreignObject x="90" y="143" width="200" height="100">
        <p className="text-op-75" style={{ marginLeft: "5px", marginTop: "7px" }}>
          Here you will see the sizes of your audiences once your organization gets started with retargeting
        </p>
      </foreignObject>
    ),
  };

  return (
    <ChartContainer width={386} height={386}>
      <Pie data={data} dataKey="value" innerRadius={145} outerRadius={190} paddingAngle={0} stroke="none">
        {data &&
          data.map((item, index) => {
            return (
              <Cell
                key={index}
                fill={item.color}
                onMouseEnter={() => {
                  onCellHover(index);
                }}
                onMouseLeave={() => {
                  onCellHover(null);
                }}
              />
            );
          })}

        <Label content={renderLabel[content]} />
      </Pie>
    </ChartContainer>
  );
};

export default PieChart;

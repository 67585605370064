import React, { useContext, useState } from "react";
import includes from "array-includes";
import { NavLink, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import "./filter.css";
import { CampaignsContext } from "../../../contexts/campaignsContext";

const renderOptions = (subFilter, filter, setFilter) => {
  const subFilterOptions = () => {
    return Object.keys(filter[subFilter]);
  };

  const setSubFilterOptionState = (option, state) => {
    filter[subFilter][option].active = state;

    setFilter(filter);
  };

  const resetSubFilters = () => {
    subFilterOptions().forEach(option => {
      setSubFilterOptionState(option, false);
    });
  };

  const subFilterOptionNames = subFilterOptions();

  const menuItems = subFilterOptionNames.map(option => {
    const optionLabel = filter[subFilter][option].name;
    const optionState = filter[subFilter][option].active;

    return (
      <DropdownItem key={`${subFilter}-${optionLabel}`} data-notoggle>
        <div
          className="custom-control custom-checkbox mt-2"
          key={`${subFilter}${optionLabel}-wrapper`}
          data-notoggle
          onClick={() => {
            setSubFilterOptionState(option, !optionState);
          }}
        >
          <input
            type="checkbox"
            checked={optionState}
            onChange={() => {
              setSubFilterOptionState(option, !optionState);
            }}
            data-notoggle
            className="custom-control-input"
          />
          <label className="custom-control-label filter__capital" data-notoggle>
            {`${optionLabel}`}
          </label>
        </div>
      </DropdownItem>
    );
  });
  menuItems.unshift(
    <DropdownItem key={`${subFilter}-filter-header`} header data-notoggle>{`Filter ${subFilter}`}</DropdownItem>
  );
  menuItems.push(<DropdownItem key={`${subFilter}-filter-divider`} divider />);
  menuItems.push(
    <DropdownItem
      key={`${subFilter}-filter-reset`}
      onClick={resetSubFilters}
      className="small"
      data-notoggle
    >{`Reset filter`}</DropdownItem>
  );
  return menuItems;
};

export default function Filter({ subFilter }) {
  const toggleId = `${subFilter}-filter-toggle`;
  const { filter, setFilter } = useContext(CampaignsContext);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const filterOptionNames = Object.keys(filter[subFilter]);

  if (includes(filterOptionNames, "")) {
    return null;
  }
  if (filterOptionNames.length === 0) {
    return null;
  }

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={ev => {
        const noToggle = ev.target.dataset.notoggle;
        if (!noToggle) {
          setIsDropdownOpen(!isDropdownOpen);
        }
      }}
    >
      <DropdownToggle nav>
        <NavLink className="filter__capital filter__expander" id={toggleId}>
          {subFilter}
        </NavLink>
      </DropdownToggle>
      <DropdownMenu className="animated fadeInUp tease header__dropdown-menu" right>
        {renderOptions(subFilter, filter, setFilter)}
      </DropdownMenu>
    </Dropdown>
  );
}

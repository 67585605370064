import React, { FC } from "react";

import {
  RenderRunning,
  RenderPreview,
  RenderShareLinks,
  AdActions,
  RenderScheduled,
  RenderCompleted,
  RenderWaiting,
} from "./CampaignActions";

const CampaignSummary: FC<{ selectedCampaignData: Record<string, any> }> = ({ selectedCampaignData }) => {
  const { status } = selectedCampaignData;

  if (status === "completed")
    return (
      <div className="col-12">
        <RenderCompleted />
        <AdActions id={selectedCampaignData._id} customerId={selectedCampaignData.customerId} />
      </div>
    );

  if (status === "running")
    return (
      <div className="col-12">
        <RenderRunning />
        <RenderPreview selectedCampaignData={selectedCampaignData} />
        <RenderShareLinks />
        <AdActions id={selectedCampaignData._id} customerId={selectedCampaignData.customerId} />
      </div>
    );

  if (status === "scheduled") {
    return (
      <div className="col-12">
        <RenderScheduled selectedCampaignData={selectedCampaignData} />
        <RenderPreview selectedCampaignData={selectedCampaignData} />
        <RenderShareLinks />
        <AdActions id={selectedCampaignData._id} customerId={selectedCampaignData.customerId} />
      </div>
    );
  }
  if (status === "waiting")
    return (
      <div className="col-12">
        <RenderWaiting selectedCampaignData={selectedCampaignData} />
        <RenderPreview selectedCampaignData={selectedCampaignData} />
        <AdActions id={selectedCampaignData._id} customerId={selectedCampaignData.customerId} />
      </div>
    );

  // Render default
  return (
    <div className="col-12">
      <RenderPreview selectedCampaignData={selectedCampaignData} />
      <AdActions id={selectedCampaignData._id} customerId={selectedCampaignData.customerId} />
    </div>
  );
};

export default CampaignSummary;

import React from "react";
import { Col, Container, Row } from "reactstrap";
import CloseButton from "../../components/buttons/CloseButton";

function ErrorPage({ readableError, handleClose, children }) {
  return (
    <div className="bg-faded">
      <div className="hv-center">
        <Container style={{ width: "800px" }}>
          <Row className="rounded shdw animated fadeInUp delay tease">
            <div className="col-12 col-md-5 bg-adway text-white p-6 rounded-left">
              <h2 className="h-adway">Error</h2>
              <p className="my-3">An error has occured.</p>
              <img alt="Adway Logo" src="./images/adway-round.png" width="48" className="mt-10" />
            </div>
            <Col className="bg-white rounded-right" style={{ display: "flex", flexDirection: "column" }}>
              {handleClose && <CloseButton handleClose={handleClose} />}
              <p style={{ margin: "4rem 4rem 1rem 4rem", minWidth: "8rem" }}>{readableError}</p>
              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ErrorPage;

import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { DashboardContext } from "../../../contexts/dashboardContext";
import activity from "../../../icons/activity.png";
import settings from "../../../icons/settings.png";
import volume from "../../../icons/volume-high.png";
import isAdwayEmail from "../../../utils/isAdwayEmail";

const generateCustomerList = (
  userData,
  history,
  selectCustomerId,
  currentCustomer,
  setCurrentCustomerInsightsEnabled
) => {
  const customerList = userData.customers;

  customerList.sort((a, b) => {
    const aName = a.name;
    const bName = b.name;
    if (aName > bName) {
      return 1;
    }
    if (aName < bName) {
      return -1;
    }
    return 0;
  });

  return customerList.map(customer => (
    <DropdownItem
      className="small"
      active={currentCustomer === customer._id}
      key={`sidebar-dropdown-${customer._id}`}
      onClick={() => {
        selectCustomerId(customer._id);
        if (customer.customerPortal) {
          setCurrentCustomerInsightsEnabled(userData.id, customer._id, customer.customerPortal.isInsightsEnabled);
        } else {
          setCurrentCustomerInsightsEnabled(userData.id, customer._id, false);
        }
        history.push("/");
      }}
    >
      {customer.name}
    </DropdownItem>
  ));
};

export default function Sidebar() {
  const { logout } = useAuth0();
  const history = useHistory();
  const {
    userData,
    setCurrentCustomerId,
    currentCustomer,
    currentCustomerInsightsEnabled,
    setCurrentCustomerInsightsEnabled,
  } = useContext(DashboardContext);

  const selectCustomerId = customerId => {
    setCurrentCustomerId(userData.id, customerId);
  };

  if (!userData) return null;

  let shouldShowInsights = false;
  if (isAdwayEmail(userData)) {
    shouldShowInsights = true;
  } else {
    if (currentCustomerInsightsEnabled) {
      const { customerId, isInsightsEnabled } = currentCustomerInsightsEnabled;
      shouldShowInsights = customerId && customerId === currentCustomer && isInsightsEnabled;
    }
  }

  return (
    <div className="d-flex flex-column sidebar box-bg m-5 br-10">
      {shouldShowInsights && (
        <NavLink
          to="/insights"
          className="tab-item tooltips p-3 text-center text-white first-tab-item"
          title="Insights"
        >
          <img src={activity} style={{ fontSize: "2em" }} alt="insights" />
        </NavLink>
      )}

      <NavLink
        to="/jobs"
        style={{ cursor: "pointer" }}
        className={`tab-item tooltips p-3 text-center text-white ${!shouldShowInsights ? "first-tab-item" : ""}`}
      >
        <img src={volume} style={{ fontSize: "2em" }} alt="jobs" />
      </NavLink>
      <UncontrolledDropdown direction="right" className="tab-item mt-auto text-center text-white">
        <DropdownToggle className="tab-item text-center text-white" nav style={{ color: "white", padding: "16px" }}>
          <img src={settings} style={{ fontSize: "2em" }} className="white-icon" alt="settings" />
        </DropdownToggle>
        <DropdownMenu
          right
          modifiers={{
            setMaxHeight: {
              enabled: true,
              order: 890,
              fn: data => {
                return {
                  ...data,
                  styles: {
                    ...data.styles,
                    overflow: "auto",
                    maxHeight: window.innerHeight - 100,
                  },
                };
              },
            },
          }}
        >
          <DropdownItem header>Change company</DropdownItem>
          {generateCustomerList(
            userData,
            history,
            selectCustomerId,
            currentCustomer,
            setCurrentCustomerInsightsEnabled
          )}
          <DropdownItem divider />
          <DropdownItem header>{userData.email}</DropdownItem>
          <DropdownItem onClick={() => logout({ returnTo: window.location.origin })} className="small">
            Sign Out
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

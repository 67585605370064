import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import ErrorPage from "../pages/error";
import SignInPage from "../pages/sign-in";

import mapRuntimeEnvVar from "../../mapRuntimeEnvVar";
import AuthZeroErrorPage from "../pages/error/authZeroErrorPage";
import "./app.css";

// Check if the user has been properly setup
const validateUser = user => {
  if (!user) return false;

  const userRoles = user["https://connect.adway.ai/roles"];
  const mappedCustomers = user["https://connect.adway.ai/customers"];
  const isSuperAdmin = user["https://connect.adway.ai/isSuperAdmin"];

  return userRoles && (mappedCustomers || isSuperAdmin);
};

const isOnlyRecruiter = userRoles => userRoles && userRoles.length === 1 && userRoles.includes("recruiter");

function App() {
  const { user, isAuthenticated, error } = useAuth0();

  // Catch error thrown by auth0 rule
  if (error) {
    return <AuthZeroErrorPage />;
  }

  // Check if user is using new permissions, will default to false if not set
  // If user is using new permissions, redirect to the admin panel instead
  const isUserUsingNewPermissions = Boolean(user && user["https://connect.adway.ai/usingNewPermissions"]);
  const isSuperAdmin = Boolean(user && user["https://connect.adway.ai/isSuperAdmin"]);
  if (isAuthenticated && isUserUsingNewPermissions && !isSuperAdmin) {
    return (
      <Route
        path="/"
        component={() => {
          window.location.replace(`${mapRuntimeEnvVar("REACT_APP_ADMIN_URI")}`);

          return null;
        }}
      />
    );
  }

  // TODO: Fix 'change password' email redirecting recruiters to customer portal
  if (isOnlyRecruiter(user && user["https://connect.adway.ai/roles"])) {
    return (
      <Route
        path="/"
        component={() => {
          window.location.replace(`${mapRuntimeEnvVar("REACT_APP_RECRUITERS_URI")}/campaigns`);

          return null;
        }}
      />
    );
  }

  // Set ImBox login
  if (isAuthenticated) {
    const _imbox = window._imbox || [];
    _imbox.push(["insertCard", "card", user.sub, `Email: ${user.email}`]);
  }

  const isValidUser = validateUser(user);

  return (
    <div className="app__container">
      <main className="app__content">
        {isAuthenticated && isValidUser && <Route path="/" render={() => <Dashboard />} />}
        {isAuthenticated && !isValidUser && (
          <Route path="/" render={() => <ErrorPage readableError={"User account has not been setup."} />} />
        )}
        {!isAuthenticated && <Route path="/" component={SignInPage} />}
      </main>
    </div>
  );
}

export default App;

// Returns a platform specific aspect ratio for images
export const getPlatformAspectRatio = platform => {
  if (platform === "linkedin") return 16 / 9;

  if (platform === "snapchat") return 9 / 16;

  return 1;
};

// Get URL from image blob
export const imageToUrl = image => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.addEventListener("error", error => reject(error));
    reader.readAsDataURL(image);
  });
};

// Create image blob from URL
export const createImageFromUrl = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

// Based on README example in https://github.com/DominicTobias/react-image-crop
export const getCroppedPng = async (imageSrc, crop) => {
  const image = await createImageFromUrl(imageSrc);

  // Rely on Canvas to render the cropped image
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // Return image blob (as a PNG)
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      resolve(blob);
    }, "image/png");
  });
};

import React from "react";
import { NavLink } from "react-router-dom";

export default function CampaignNavigation({ campaignID, campaignType, selectedCampaignData }) {
  return (
    <div>
      <div className="py-4">
        <strong>{selectedCampaignData.title}</strong><br/>
        <small>{selectedCampaignData.atsJobPostId}</small>
      </div>
      <div className="topnav d-flex flex-row">
        <NavLink to={`/${campaignType}/${campaignID}/`} className="tab-item p-3 text-center" exact>
          <ion-icon name="bookmarks" style={{ fontSize: "2em" }} />
          <br />
          <small>Summary</small>
        </NavLink>
        <NavLink to={`/${campaignType}/${campaignID}/preview`} className="tab-item p-3 text-center">
          <ion-icon name="search" style={{ fontSize: "2em" }} />
          <br />
          <small>Preview</small>
        </NavLink>
        {selectedCampaignData.landing_page_id && (
          <NavLink to={`/${campaignType}/${campaignID}/apply`} className="tab-item p-3 text-center">
            <ion-icon name="globe" style={{ fontSize: "2em" }} />
            <br />
            <small>Apply</small>
          </NavLink>
        )}
      </div>
    </div>
  );
}

import { useAuth0 } from "@auth0/auth0-react";
import Plausible from "plausible-tracker";
import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from "react";

interface ITrackingContextProviderProps {
  children?: ReactNode;
}

interface ITrackingState {
  role: string;
  plausible: any;
  onAdActions: (campaignId: string, customerId: string) => void;
}

export const TrackingContext = createContext<ITrackingState | undefined>(undefined);

export const TrackingContextProvider: FC<ITrackingContextProviderProps> = ({ children }) => {
  const { getIdTokenClaims, user } = useAuth0();
  const [role, setRole] = useState<string>("");

  const plausible = Plausible({
    domain: process.env.REACT_APP_CUSTOMER_PORTAL_PLAUSIBLE_DOMAIN,
    trackLocalhost: true,
    apiHost: process.env.REACT_APP_APP_ENV !== "development" ? "/portal/proxy" : undefined,
  });

  useEffect(() => {
    plausible.enableAutoPageviews();
  }, []);

  useEffect(() => {
    const getRole = async () => {
      if (!user) {
        return;
      }

      const claims = await getIdTokenClaims();
      if (!claims) {
        return;
      }

      const rolesClaim = claims["https://connect.adway.ai/roles"];

      setRole(rolesClaim.join(","));
    };

    getRole();
  }, [getIdTokenClaims, user]);

  const onAdActions = (campaignId: string, customerId: string): void => {
    plausible.trackEvent("ad_actions", {
      props: {
        role,
        campaignId,
        customerId,
      },
    });
  };

  return (
    <TrackingContext.Provider
      value={{
        role,
        plausible,
        onAdActions,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error("useTracking() should only be used within a <TrackingContextProvider>");
  }

  return context;
};

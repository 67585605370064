import { useAuth0 } from "@auth0/auth0-react";

function SignInPage() {
  const { loginWithRedirect, isLoading } = useAuth0();

  // Automatically redirect to login page if we're not loading the login status
  if (!isLoading) loginWithRedirect();

  return null;
}

export default SignInPage;

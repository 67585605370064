import React, { FC, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useCustomerCampaignCount, useInsights } from "../../../connect-api";
import { DashboardContext } from "../../../contexts/dashboardContext";
import isAdwayEmail from "../../../utils/isAdwayEmail";
import AudienceData from "./AudienceData";
import { calculateAudienceUnder1000, getColor, separateThousands } from "./helpers";
import "./insights.css";
import { Audience, Category } from "./interfaces";
import PieChart from "./PieChart";
import Reach from "./Reach";

const placeholderData = [
  {
    name: "",
    value: 33,
    color: "#555555",
  },
  {
    name: "",
    value: 33,
    color: "#333333",
  },
  {
    name: "",
    value: 33,
    color: "#444444",
  },
];

const Insights: FC = () => {
  const { currentCustomer, userData, currentCustomerInsightsEnabled } = useContext(DashboardContext);

  const { data: customerData = {} }: any = useInsights(currentCustomer);
  const { runningAds, scheduledAds } = useCustomerCampaignCount(currentCustomer);

  const { facebookAudiences = {}, analytics = {} } = customerData;

  const [selectedCellIndex, setSelectedCellIndex] = useState<number>();
  const categoryData: Category[] = [];
  let indexCounter = 0;

  const onCellHover = (index: number) => {
    setSelectedCellIndex(index);
  };

  const facebookAudienceList = Object.entries(facebookAudiences);
  let audiencesUnder1000: Audience[] = [];
  if (facebookAudienceList.length) {
    facebookAudienceList.forEach(([category, size]) => {
      const audienceSize = Number(size);
      if (audienceSize > 1000) {
        const color = getColor(indexCounter);
        categoryData.push({
          color,
          value: audienceSize,
          name: category,
          index: indexCounter,
        });
      } else {
        audiencesUnder1000.push({
          value: audienceSize,
          name: category,
        });
      }

      indexCounter += 1;
    });
  }
  const facebookAudienceListSizeOrdered =
    categoryData.length > 0 ? categoryData.sort((a, b) => (a.index > b.index ? 1 : -1)) : [];

  const summed = calculateAudienceUnder1000(customerData.facebookAudienceSize, facebookAudiences);

  if (audiencesUnder1000.length > 0) {
    audiencesUnder1000 = audiencesUnder1000.sort((a, b) => (a.name > b.name ? 1 : -1));
    categoryData.push({
      color: "#999999",
      value: summed,
      name: "Below 1000",
      index: 1000,
    });
  }

  if (!isAdwayEmail(userData) && currentCustomerInsightsEnabled) {
    const { customerId, isInsightsEnabled } = currentCustomerInsightsEnabled;
    const shouldNotRender = customerId !== currentCustomer || !isInsightsEnabled;
    if (shouldNotRender)
      return (
        <InsightsContainer className="container insights-bg d-flex flex-column min-vh-100 justify-content-center align-items-center text-white ">
          <div className="row text-center">
            <div className="col-12">
              <h1 className="text-uppercase">Under construction</h1>
              <p>We are building new cool features for you. Hang tight!</p>
              <NavLink to="/jobs" className="p-3 text-center text-white pointer text-muted" title="Insights">
                Back to your campaigns
              </NavLink>
            </div>
          </div>
        </InsightsContainer>
      );
  }

  return (
    <InsightsContainer className="container-fluid insights-bg">
      <div className="row h-100 align-self-md-center">
        <div className="col-12 p-5 scroll-y text-center text-white h-100">
          <h3>Insights</h3>
          <p className="text-op-75 my-3">Real time data of your current audience, ads and performance.</p>
          <div className="row">
            <div className="col-6 col-sm-6 col-lg-6 col-xl-2 mt-3 text-left order-xl-1 order-1">
              <div className="box-bg m-2 p-4 br-10 w-100">
                <Reach label="Impressions" keyName="impressions" currentReach={analytics} />
              </div>
              <div className="box-bg m-2 p-4 br-10 w-100 mt-4">
                <Reach label="Clicks" keyName="clicks" currentReach={analytics} />
              </div>
            </div>
            <div className="col-12 col-xl-8 col-lg-12 order-sm-12 order-xl-2 order-3 mt-3">
              <div className="box-bg m-2 p-4 br-10 w-100">
                <PieChart
                  customerData={customerData}
                  content={categoryData.length < 1 ? "placeholder" : "label"}
                  data={categoryData.length < 1 ? placeholderData : categoryData}
                  onCellHover={onCellHover}
                />
                <div className="container mt-10 overflow-hidden">
                  <div className="row mt-4 justify-content-center">
                    <div className="col-lg-6 col-5 m-1 p-4 br-10 insights-background text-left">
                      <h2>{separateThousands(runningAds)}</h2>
                      <p className="mb-0 text-op-75">Running ads</p>
                    </div>
                    <div className="col-5 m-1 p-4 br-10 insights-background text-left">
                      <h2>{separateThousands(scheduledAds)}</h2>
                      <p className="mb-0 text-op-75">Scheduled ads</p>
                    </div>
                  </div>
                  <AudienceData
                    audiences={facebookAudienceListSizeOrdered}
                    audiencesUnder1000={audiencesUnder1000}
                    selectedIndex={selectedCellIndex}
                  />
                </div>
              </div>
            </div>

            <div className="mt-3 col-6 col-sm-6 col-md-6 col-xl-2 col-lg-6 order-xl-2 order-2">
              <div className="box-bg m-2 p-4 br-10 text-left">
                <Reach label="Reactions" keyName="reactions" currentReach={analytics} />
              </div>
              <div className="box-bg m-2 p-4 br-10 text-left mt-4">
                <Reach label="Comments" keyName="comments" currentReach={analytics} />
              </div>
              <div className="box-bg m-2 p-4 br-10 text-left mt-4">
                <Reach label="Shares" keyName="shares" currentReach={analytics} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </InsightsContainer>
  );
};

const InsightsContainer = styled("div")`
  .recharts-wrapper {
    margin: 0 auto;
  }
`;

export default Insights;

import React from "react";
import "./buttons.css";

const CloseButton = ({ handleClose }) => (
  <button
    onClick={handleClose}
    className="close-btn"
    style={{
      alignSelf: "flex-end",
      margin: "0.7rem 0",
    }}
  >
    <ion-icon name="close" style={{ fontSize: "1.2rem" }} />
  </button>
);

export default CloseButton;

import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useEffect, useState } from "react";
import { getCustomerNames } from "../connect-api";

const getStoredCustomerId = userId => {
  return localStorage.getItem(`current_customer_${userId}`);
};

const storeCustomerId = (userId, customerId) => {
  localStorage.setItem(`current_customer_${userId}`, customerId);
};

const storeInsightsEnabled = (userId, customerId, isInsightsEnabled) => {
  localStorage.setItem(
    `current_customer_${userId}_insights_enabled`,
    JSON.stringify({ customerId: customerId, isInsightsEnabled })
  );
};

export const DashboardContext = createContext({
  userData: null,
  currentCustomer: null,
  setCurrentCustomerId: null,
  currentCustomerInsightsEnabled: { customerId: undefined, isInsightsEnabled: false },
  setCurrentCustomerInsightsEnabled: null,
});

export const DashboardContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [currentCustomer, setCurrentCustomerIdState] = useState(null);
  const [currentCustomerInsightsEnabled, setCurrentCustomerInsightsEnabledState] = useState(null);

  const setCurrentCustomerId = (userId, customerId) => {
    storeCustomerId(userId, customerId);
    setCurrentCustomerIdState(customerId);
  };
  const setCurrentCustomerInsightsEnabled = (userId, customerId, isInsightsEnabled) => {
    storeInsightsEnabled(userId, customerId, isInsightsEnabled);
    setCurrentCustomerInsightsEnabledState({ customerId, isInsightsEnabled });
  };

  const { user, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    async function fetchData() {
      if (!user) return;

      const token = await getAccessTokenSilently();

      const customerIds = user["https://connect.adway.ai/customers"].map(customer => customer.id);
      const userRoles = user["https://connect.adway.ai/roles"];

      // Check if the user is a super-admin
      const isSuperAdmin = user["https://connect.adway.ai/isSuperAdmin"];

      // If the user is a super admin, don't filter by customer ids
      const customerDataListResponse = await getCustomerNames(token, isSuperAdmin ? null : customerIds);
      const customerDataList = customerDataListResponse && (await customerDataListResponse.json());

      const userData = {
        email: user.email,
        id: user.sub,
        roles: userRoles,
        customers: customerDataList,
        isSuperAdmin,
      };

      setUserData(userData);
      if (!currentCustomer) {
        const fallbackId = userData.customers[0] ? userData.customers[0]._id : null;
        const selectedCustomerId = getStoredCustomerId(user.sub) || fallbackId;
        let isInsightsEnabled = false;

        const customer = customerDataList.find(x => x.id === selectedCustomerId);
        if (customer && customer.customerPortal && customer.customerPortal.isInsightsEnabled) isInsightsEnabled = true;

        setCurrentCustomerId(user.sub, selectedCustomerId);
        setCurrentCustomerInsightsEnabled(user.sub, selectedCustomerId, isInsightsEnabled);
      }
    }

    fetchData();
  }, [user, currentCustomer, getAccessTokenSilently]);

  return (
    <DashboardContext.Provider
      value={{
        userData,
        currentCustomer,
        setCurrentCustomerId,
        currentCustomerInsightsEnabled,
        setCurrentCustomerInsightsEnabled,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

// Extract ad previews from campaign data
export const extractAdPreviews = campaignData => {
  if (!campaignData) {
    return [];
  }

  const previewImageList = [];

  // Iterate over all the ads and extract the images
  const { ads } = campaignData;
  ads.forEach(({ platform, status }) => {
    const { creative, creatives } = platform;

    if (creatives) {
      Object.keys(creatives).forEach(key => {
        creatives[key].previewImageUrl &&
          previewImageList.push({
            channel: key,
            url: creatives[key].previewImageUrl || creatives[key].videoThumbnail,
            status,
          });
      });
    }

    if (creative) {
      creative.previewImageUrl &&
        previewImageList.push({
          channel: platform.type,
          url: creative.previewImageUrl || creative.videoThumbnail,
          status,
        });
    }
  });

  return previewImageList;
};

export const getCreativesFromCampaign = campaignData => {
  const creatives = [];
  campaignData.ads.forEach(ad => {
    if (ad.platform.creative) {
      creatives.push({ type: ad.adType, name: ad.platform.type, ...ad.platform.creative });
    }
    if (ad.platform.creatives) {
      Object.entries(ad.platform.creatives).forEach(entry => {
        creatives.push({ type: ad.adType, name: entry[0], ...entry[1] });
      });
    }
  });
  return creatives;
};

const getPreviewUrlByPlatformType = campaignData => {
  const campaignCreatives = getCreativesFromCampaign(campaignData)[0];

  if (campaignCreatives) {
    return campaignCreatives.previewImageUrl || campaignCreatives.videoThumbnail;
  }

  return null;
};

const getStartingAnalytics = () => ({
  comments: 0,
  shares: 0,
  impressions: 0,
  reach: 0,
  clicks: 0,
  reactions: 0,
  applicationsStarted: 0,
  applicationsCompleted: 0,
});

export const getAggregatedAnalytics = (ads = []) => {
  const safelyExtractAnalyticsValue = (ad, prop) => {
    return (ad && ad.analytics && ad.analytics[prop]) || 0;
  };

  return ads.reduce((prevReach, currAd) => {
    return {
      comments: prevReach.comments + safelyExtractAnalyticsValue(currAd, "comments"),
      shares: prevReach.shares + safelyExtractAnalyticsValue(currAd, "shares"),
      impressions: prevReach.impressions + safelyExtractAnalyticsValue(currAd, "impressions"),
      reach: prevReach.reach + safelyExtractAnalyticsValue(currAd, "reach"),
      clicks: prevReach.clicks + safelyExtractAnalyticsValue(currAd, "clicks"),
      reactions: prevReach.reactions + safelyExtractAnalyticsValue(currAd, "reactions"),
      applicationsStarted: prevReach.applicationsStarted + safelyExtractAnalyticsValue(currAd, "applicationsStarted"),
      applicationsCompleted:
        prevReach.applicationsCompleted + safelyExtractAnalyticsValue(currAd, "applicationsCompleted"),
    };
  }, getStartingAnalytics());
};

// Select an ad preview from campaign data
export const selectAdPreview = campaignData => (campaignData ? getPreviewUrlByPlatformType(campaignData) : null);

// Calculates the pathPrefix
export const getPathPrefix = () => {
  if (process.env.NODE_ENV === "development") return "";

  return "/portal";
};

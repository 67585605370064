import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Progress } from "reactstrap";
import moment from "moment";
import "./campaign.css";
import { selectAdPreview } from "../../../utils";
import UseAnalyticsHook from "./UseAnalyticsHook";

export default function CampaignCard({ data }) {
  const location = useLocation();
  const analytics = UseAnalyticsHook(data.ads);
  const { campaignType } = useParams();

  const separateThousands = number => {
    if (!number) return number;
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  // TODO: Once we have more examples of test data, rewrite all of this code
  const startDate = data.schedule ? data.schedule.start : null;
  const endDate = data.schedule ? data.schedule.end : null;
  const today = moment();
  const dateStart = moment(startDate);
  let dateStartText = startDate ? dateStart.clone().format("MMMM Do YYYY") : null;
  const dateEnd = endDate ? moment(endDate) : 0;

  let dateEndText = dateEnd ? dateEnd.clone().format("MMMM Do YYYY") : null;

  dateEndText = dateEndText === "January 1st 1970" ? null : dateEndText;

  let campaignRunTime = dateEnd && startDate ? Math.ceil(dateEnd.diff(dateStart, "d", true)) : 0;
  let campaignCurrentProgress = startDate ? Math.ceil(Math.abs(dateStart.diff(today, "d", true))) : 0;

  if (!dateEnd) campaignRunTime = 1;
  if (campaignCurrentProgress > campaignRunTime) {
    campaignCurrentProgress = campaignRunTime;
  }

  let status = data.status;

  const progressActive = data.status === "running";

  if (status === "scheduled" || status === "new") {
    campaignCurrentProgress = 0;
  }
  if (!data.aggregatedAnalytics && status !== "scheduled") {
    if (!status) {
      // This means that we didn't have any data syncs so far
      // Fill in blank data
      status = "";
      campaignCurrentProgress = 0;
      dateStartText = "";
      dateEndText = "";
    } else {
      status += " - ";
    }
    status += "Synchronizing data...";
  }

  // Calculate the link for the campaign selection
  const campaignLink = `/${campaignType}/${data._id}`;

  let cardClassName = "cards-item p-4 rounded ";
  // Check if already selected
  if (location.pathname.indexOf(campaignLink) !== -1) {
    cardClassName += "bg-faded";
  }

  // Make sure we don't crash the app by assigning {} to undefined aggregatedAnalytics
  if (!data.aggregatedAnalytics) data.aggregatedAnalytics = {};

  // Check for a preview img URL being available
  const previewImg = selectAdPreview(data);

  const uniqueCities = [
    ...new Set((data.locations || []).map(location => (location.city ? location.city.trim() : undefined))),
  ];

  return (
    <Link to={campaignLink}>
      <div className={cardClassName} style={{ marginTop: "28px" }}>
        {previewImg && (
          <Link to={`${campaignLink}/preview`}>
            <img src={previewImg} alt="Ad Preview" width="90" className="float-right ml-5" />
          </Link>
        )}
        <h4 style={{ color: "black", minHeight: 50 }}>
          {data.title}
          <div className="d-flex flex-row text-muted campaign__title_subtitle">
            <div className="d-flex flex-row align-items-center">
              <span>{data.atsJobPostId}</span>
            </div>
          </div>
        </h4>
        <p className="my-2 text-muted">
          {(data.categories || []).map(category => {
            return (
              <span key={category} className="badge badge-success">
                {category}
              </span>
            );
          })}
          {uniqueCities.map(city => (
            <span key={city} style={{ marginLeft: "3px" }} className="badge badge-secondary">
              {city}
            </span>
          ))}
        </p>
        <div className="d-flex flex-row text-muted">
          <div className="py-3">
            <div className="d-flex flex-row align-items-center">
              <span>
                <strong>{separateThousands(analytics.impressions)}</strong>
                <br />
                impressions
              </span>
              <span className="px-3">
                <ion-icon name="arrow-round-forward" style={{ marginLeft: "14.4px" }} />
              </span>
              <span>
                <strong>{separateThousands(analytics.clicks)}</strong>
                <br />
                clicked
              </span>
              <span className="px-3">
                <ion-icon name="heart" style={{ marginLeft: "14.4px" }} />
              </span>
              <span className="pr-4">
                <strong>{separateThousands(analytics.reactions)}</strong>
                <br />
                reacts
              </span>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div>
          <small className="text-muted" style={{ textTransform: "capitalize" }}>
            {status}
          </small>
        </div>
        <Progress
          style={{ height: "0.5rem" }}
          max={campaignRunTime}
          value={campaignCurrentProgress}
          animated={progressActive}
          striped={progressActive}
          color="success"
        />
        <div>
          <small className="text-muted">{dateStartText}</small>
          <small className="text-muted float-right">{dateEndText}</small>
        </div>
      </div>
    </Link>
  );
}

import React from "react";
import { Col } from "reactstrap";
import Campaign from "../campaign-card";
import ProgressCircle from "../progress-circle";
import "./listing.css";

export default function Listing({ list, isLoading }) {
  const renderList = () => {
    // Check for empty data
    if (list === null) {
      return <ProgressCircle />;
    }
    if (Object.keys(list).length === 0) {
      return <h4 className="text-muted text-center mt-5">No job ads published yet, stay tuned...</h4>;
    }

    const renderListItems = list.map(item => {
      return <Campaign data={item} key={item._id} />;
    });

    if (isLoading) {
      renderListItems.push(<ProgressCircle key="progress" />);
    }

    return renderListItems;
  };

  return (
    <Col className="listing__container p-0" size={12}>
      <ul className="list-unstyled">{renderList()}</ul>
    </Col>
  );
}

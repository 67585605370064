import React, { useContext } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import CampaignNavigation from "./navigation";
import CampaignSummary from "./Summary";
import CampaignPreview from "./preview";
import CampaignShare from "./share";
import CampaignChannels from "./channels";
import { CampaignsContext } from "../../../contexts/campaignsContext";

export default function CampaignDetails() {
  const renderPlaceholder = () => (
    <div className="col-5 text-white bg-adway0 scroll-y px-5" style={{ maxHeight: "100%" }} id="scroll-content"></div>
  );

  const { campaignList } = useContext(CampaignsContext);
  const { params } = useRouteMatch("/:campaignType/:campaignId?");
  const { campaignType, campaignId } = params;

  if (!campaignList || !campaignId) return renderPlaceholder();

  const selectedCampaignData = campaignList.find(campaignData => campaignData._id === campaignId);

  if (!selectedCampaignData) return renderPlaceholder();

  return (
    <div className="col-5 text-white bg-adway0 scroll-y px-5" style={{ maxHeight: "100%" }} id="scroll-content">
      <Route path="/:campaignType/:campaignID">
        <CampaignNavigation
          campaignID={campaignId}
          campaignType={campaignType}
          selectedCampaignData={selectedCampaignData}
        />
      </Route>
      <div className="row py-5">
        <Switch>
          <Route exact path="/:campaignType/:campaignID">
            <CampaignSummary
              campaignID={campaignId}
              campaignType={campaignType}
              selectedCampaignData={selectedCampaignData}
            />
          </Route>
          <Route path="/:campaignType/:campaignID/preview">
            <CampaignPreview selectedCampaignData={selectedCampaignData} />
          </Route>
          <Route path="/:campaignType/:campaignID/share">
            <CampaignShare selectedCampaignData={selectedCampaignData} />
          </Route>
          <Route path="/:campaignType/:campaignID/channels">
            <CampaignChannels selectedCampaignData={selectedCampaignData} />
          </Route>
          <Route path="/:campaignType/:campaignID/edit">
            <CampaignPreview selectedCampaignData={selectedCampaignData} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

import moment from "moment";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useParams } from "react-router-dom";
import React, { FC, useCallback, useState } from "react";
import { get } from "lodash";

import IonIcon from "../ionicon";
import { selectAdPreview } from "../../../utils";
import mapRuntimeEnvVar from "../../../mapRuntimeEnvVar";
import { connectFetch } from "../../../connect-api/utils";
import { useTracking } from "../../../contexts/trackingContext";
const recruitersPageUrl = mapRuntimeEnvVar("REACT_APP_RECRUITERS_URI");

interface UrlParams {
  campaignID: string;
  campaignType: string;
}

export const RenderRunning: FC = () => (
  <div className="alert alert-success p-4" role="alert">
    <h4 className="alert-heading h-adway">Running</h4>
    <p className="text-white">
      Your job ad is now visible in social media, you can still boost the ad to reach a wider audience and gain more
      traction.
    </p>
  </div>
);

export const RenderPreview: FC<{ selectedCampaignData?: Record<string, any> }> = ({ selectedCampaignData }) => {
  const previewImg = selectAdPreview(selectedCampaignData);
  const { campaignID, campaignType } = useParams<UrlParams>();

  if (!previewImg) return null;
  return (
    <div className="alert alert-light p-4 mt-4" role="alert">
      <img src={previewImg} alt="Ad Preview" width="90" className="float-right ml-3" />
      <h4 className="alert-heading">Preview</h4>
      <p>See how your job ad will look like in social media before it's published.</p>
      <Link to={`/${campaignType}/${campaignID}/preview`} className="btn btn-lg btn-outline-secondary">
        Sneak peek
        <IonIcon name="search" style={{ marginLeft: "14.4px" }} />
      </Link>
      <div className="clearfix" />
    </div>
  );
};

export const RenderShareLinks: FC = () => {
  const { campaignID, campaignType } = useParams<UrlParams>();
  return (
    <div className="alert alert-light p-4 mt-4" role="alert">
      <h4 className="alert-heading">Share</h4>
      <p>Share your ads to reach more candidates.</p>
      <Link to={`/${campaignType}/${campaignID}/share`} className="btn btn-lg btn-outline-secondary">
        Shareable links
        <IonIcon name="share" style={{ marginLeft: "14.4px" }} />
      </Link>
    </div>
  );
};

export const RenderScheduled: FC<{ selectedCampaignData: Record<string, any> }> = ({ selectedCampaignData }) => {
  if (!selectedCampaignData || !selectedCampaignData.tracking_data) return null;

  const adStartDate = moment(selectedCampaignData.start_time);
  const startsIn = adStartDate.fromNow();

  return (
    <div className="alert alert-success p-4" role="alert">
      <h4 className="alert-heading h-adway">Scheduled</h4>
      <p className="text-white">Your job ad is scheduled and will be visible in social media within 24 hours.</p>
      <p className="text-white text-op-75 mb-0 mt-2">
        {Math.abs(adStartDate.diff(moment(), "h")) < 24 && (
          <small>
            Auto published in <strong>{startsIn}</strong>
          </small>
        )}
      </p>
    </div>
  );
};

export const RenderCompleted: FC = () => (
  <div className="alert alert-success p-4" role="alert">
    <h4 className="alert-heading h-adway">Completed</h4>
    <p className="text-white">
      Your job ad is no longer visible in social media, publish it again to reach a wider audience and gain more
      traction.
    </p>
  </div>
);

export const RenderWaiting: FC<{ selectedCampaignData: Record<string, any> }> = ({ selectedCampaignData }) => {
  if (!selectedCampaignData || !selectedCampaignData.tracking_data) return null;

  return (
    <div className="alert alert-success p-4" role="alert">
      <h4 className="alert-heading h-adway">Waiting Activation</h4>
      <p className="text-white">Your job ad is waiting for your activation.</p>
    </div>
  );
};

interface IAdActionsProps {
  id: string;
  customerId: string;
}

export const AdActions: FC<IAdActionsProps> = ({ id, customerId }) => {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { onAdActions } = useTracking();

  const getToken = useCallback(async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();

    onAdActions(id, customerId);

    try {
      const customerResponse = await connectFetch("customer", token, `/${customerId}`);

      const customer = await customerResponse.json();
      const authenticationRequired = get(customer, "recruiterPortal.authenticationRequired");

      if (authenticationRequired) {
        setLoading(false);

        window.open(`${recruitersPageUrl}/campaigns/${id}`, "_blank");

        return;
      }

      const userResponse = await connectFetch("user", token, `/public-access-tokens/${id}?createOnNotFound=true`);

      const publicAccessToken = await userResponse.json();

      setLoading(false);

      window.open(`${recruitersPageUrl}?campaignId=${id}&token=${publicAccessToken.token}`, "_blank");
    } catch (error) {
      setLoading(false);
    }
  }, [id, getAccessTokenSilently]);

  return (
    <div>
      <p className="mt-4">
        <small>
          Perform actions on ad in a new <strong>browser window</strong>
        </small>
      </p>
      <button disabled={loading} className="btn btn-lg btn-outline-success" onClick={() => getToken()}>
        Ad actions
        <div className="pl-3">
          {loading ? <span className="spinner" style={{ margin: "auto" }} /> : <IonIcon name="share-alt" />}
        </div>
      </button>
    </div>
  );
};

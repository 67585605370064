import React, { useContext } from "react";
import { Navbar, Nav, NavItem, NavbarBrand } from "reactstrap";
import { CampaignsContext } from "../../../contexts/campaignsContext";
import { DashboardContext } from "../../../contexts/dashboardContext";
import Filter from "../filter";
import Search from "../search";
import "./menu.css";

export default function Menu() {
  const { filter } = useContext(CampaignsContext);
  const { userData, currentCustomer } = useContext(DashboardContext);
  const { customers: customerList, isSuperAdmin } = userData || {};

  const activeCustomer = (customerList || []).find(customer => customer._id === currentCustomer) || {};

  const renderFilters = () => {
    if (filter !== null) {
      const subFilters = Object.keys(filter);
      return subFilters.map(subFilter => {
        return (
          <NavItem key={`${subFilter}-navitem`}>
            <Filter subFilter={subFilter} key={subFilter} />
          </NavItem>
        );
      });
    } else {
      return null;
    }
  };

  return (
    <div className="menu col-12 p-0">
      <div className="row">
        {isSuperAdmin && (
          <div className="col-12">
            <h4>{activeCustomer.name || ""}</h4>
          </div>
        )}
        <div className="col-12">
          <Search />
        </div>
        <Navbar expand="lg" light>
          <NavbarBrand className="menu__filter-head pl-0 pr-4">
            <p style={{ fontSize: "14.4px", margin: "auto" }}>Filter</p>
          </NavbarBrand>
          <Nav>{renderFilters()}</Nav>
        </Navbar>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { connectFetch } from "./utils";

export const useInsights = customerId => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchCustomer = async customerId => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const res = await connectFetch("customer", token, `/${customerId}`);
        if (res.ok) {
          const customer = await res.json();
          setData(customer);
        }
      } catch (error) {
        setError(error.errorMessage);
      } finally {
        setLoading(false);
      }
    };

    if (customerId) {
      fetchCustomer(customerId);
    }
  }, [customerId, getAccessTokenSilently]);

  return {
    error,
    loading,
    data,
  };
};

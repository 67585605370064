import React, { useState, useContext } from "react";
import { CampaignsContext } from "../../../contexts/campaignsContext";

import { Input } from "reactstrap";

// To prevent request spam during search, wait for 500ms of idling (no user input) and then make the request
const SEARCH_AFTER_IDLE_FOR_MS = 500;

export default function Search() {
  const { setSearchQuery } = useContext(CampaignsContext);
  const [timeoutId, setTimeoutId] = useState(null);
  const [pendingSearchQuery, setPendingSearchQuery] = useState("");

  const runSearch = newQuery => {
    removeTimeout(); // Clear all possible pending idle timeouts
    setSearchQuery(newQuery); // Submit search query to context
  };

  const removeTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  const waitForIDLE = newQuery => {
    // Clear previous timeout
    removeTimeout();

    const newTimeoutId = setTimeout(() => {
      runSearch(newQuery);
    }, SEARCH_AFTER_IDLE_FOR_MS);
    setTimeoutId(newTimeoutId);
  };

  const onButtonPress = ev => {
    if (ev.key !== "Enter") return;

    submitSearchQuery(true, pendingSearchQuery);
  };

  const onSearchInput = ev => {
    const newQuery = ev.target.value;
    setPendingSearchQuery(newQuery);
    submitSearchQuery(false, newQuery);
  };

  const submitSearchQuery = (triggeredByEnter, newQuery) => {
    if (triggeredByEnter) {
      // Submit immediately
      runSearch(newQuery);
    } else {
      // Wait for the user to be idle
      waitForIDLE(newQuery);
    }
  };

  const placeholder = `Search...`;
  return (
    <Input
      bsSize="lg"
      type="text"
      placeholder={placeholder}
      value={pendingSearchQuery}
      onChange={onSearchInput}
      onKeyDown={onButtonPress}
    />
  );
}

import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./views/app";
import config from "./config";
import { getPathPrefix } from "./utils";
import "./index.css";
import "./theme.css";
import "./animations.css";
import "./pie.css";
import { TrackingContextProvider } from "./contexts/trackingContext";

const rootElement = document.getElementById("root");

function render(Component) {
  const baseName = getPathPrefix();
  const redirectUri = `${window.location.origin}${getPathPrefix()}`;

  ReactDOM.render(
    <Router basename={baseName}>
      <Auth0Provider
        domain={config.AUTH0_DOMAIN}
        clientId={config.AUTH0_CLIENT_ID}
        audience={config.AUTH0_AUDIENCE}
        redirectUri={redirectUri}
        scope="openid profile"
      >
        <TrackingContextProvider>
          <div>
            <Component />
          </div>
        </TrackingContextProvider>
      </Auth0Provider>
    </Router>,
    rootElement
  );
}

if (module.hot) {
  module.hot.accept("./views/app", () => {
    render(require("./views/app").default);
  });
}

render(App);

import React from "react";
import RenderSocialLink from "./RenderSocialLink";

export default function CampaignChannels({ selectedCampaignData }) {
  const renderJobSiteLink = ad => {
    const source = ad.platform === "ams" ? "Arbetsförmedlingen" : ad.platform;
    const type = ""; //TODO: Are there types for job boards?

    return (
      <button
        className="btn btn-block btn-sm btn-outline-secondary p-2 px-3 d-flex flex-row align-items-center text-left"
        key={ad._id}
      >
        <div className="pr-3">
          <ion-icon name="clipboard" style={{ marginLeft: "14.4px" }} />
          <ion-icon name="git-branch" style={{ marginLeft: "14.4px" }} />
        </div>
        <div>
          <strong>{source}</strong>
          <br />
          <small>{type}</small>
        </div>
      </button>
    );
  };

  const ads = selectedCampaignData.ads || [];

  if (!ads.length) return null;

  ads.sort(sortAdsByPriorityMapping);

  // Separate social posts from job board posts
  const socialPosts = ads.filter(ad => ad.platform.type !== "ams");
  const boardPosts = ads.filter(ad => ad.platform.type === "ams");

  return (
    <div className="col-12">
      {socialPosts.length && (
        <p className="text-op-75 mb-5">Your job ad was published in the following social media networks.</p>
      )}

      {socialPosts.length
        ? socialPosts.map((ad, index) => <RenderSocialLink key={index} ad={ad} adId={selectedCampaignData._id} />)
        : null}

      {boardPosts.length ? (
        <p className="text-op-75 my-5">Your job ad was published on the following job boards.</p>
      ) : null}

      {boardPosts.length ? boardPosts.map(ad => renderJobSiteLink(ad)) : null}
    </div>
  );
}

function sortAdsByPriorityMapping(a, b) {
  const priorityMapping = {
    facebook: 0,
    instagram: 1,
    snapchat: 2,
    linkedin: 3,
    ams: 4,
  };
  const prioA = priorityMapping[a.platform.type];
  const prioB = priorityMapping[b.platform.type];

  if (prioA > prioB) return 1;
  if (prioA < prioB) return -1;

  return 0;
}

import React, { useState, createContext, useContext, useEffect } from "react";
import { useRouteMatch } from "react-router";
import { CampaignsContext } from "./campaignsContext";
import { detailedDiff } from "deep-object-diff";
import { getAdsByCampaignId, updateAds, uploadImageAsset } from "../connect-api";
import { cloneDeep } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";

export const EditorContext = createContext({
  commitChanges: null,
  getUnsavedChanges: null,
  combinedAdData: null,
  error: null,
  isLoading: null,
  setCombinedData: null,
  addPendingImageUpdate: null,
});

// Extract creatives from ad data
const extractLoadedCreatives = loadedAds => {
  const loadedCreatives = [];

  loadedAds.forEach(ad => {
    if (ad.platform && ad.platform.creatives)
      Object.entries(ad.platform.creatives).forEach(([platformType, creative]) => {
        loadedCreatives.push({
          ...creative,
          platformType,
          adId: ad._id,
        });
      });
  });

  return loadedCreatives;
};

// Calculate combined ad date that will be shown in the UI
const extractCombinedData = loadedAds => {
  const combinedData = { title: null };

  const loadedCreatives = extractLoadedCreatives(loadedAds);

  if (!loadedCreatives.length) return null;

  loadedCreatives.forEach(creative => {
    combinedData.title = creative.title;

    // Separate platform specific data into a subproperty
    if (!combinedData[creative.platformType])
      combinedData[creative.platformType] = { body: null, mediaUrl: null, mediaType: null };

    combinedData[creative.platformType].body = creative.body;
    combinedData[creative.platformType].mediaUrl = creative.mediaUrl;
    combinedData[creative.platformType].mediaType = creative.mediaType;
  });

  return combinedData;
};

// Apply the combined data to ad data in order to merge the changes
const applyCombinedData = (combinedData, loadedAds) => {
  const updatedAds = [];

  loadedAds.forEach(ad => {
    const updatedAd = cloneDeep(ad);

    if (ad.platform && ad.platform.creatives)
      Object.entries(ad.platform.creatives).forEach(([platformType, creative]) => {
        const updatedCreative = {
          ...creative,
          title: combinedData.title,
          ...combinedData[platformType],
        };

        updatedAd.platform.creatives[platformType] = updatedCreative;
      });

    updatedAds.push(updatedAd);
  });

  return updatedAds;
};

export const EditorContextProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  // Campaign data
  const { params } = useRouteMatch("/:campaignType/:campaignId?");
  const { campaignId } = params;
  const { campaignList } = useContext(CampaignsContext);

  // Ad data
  const [loadedAds, setLoadedAds] = useState([]);
  const [combinedAdData, setCombinedData] = useState({});

  // Error/loading states
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Pending image updates
  const [pendingImageUpdates, setPendingImageUpdates] = useState({});

  // Submit changes
  const commitChanges = async () => {
    // Make the API calls
    setIsLoading(true);

    const token = await getAccessTokenSilently();

    // Applied ap data
    const newCombinedAppData = cloneDeep(combinedAdData);

    // Process pending image uploads if any
    const updatedImageMapping = {};
    const campaignData = campaignList.find(campaign => campaign._id === campaignId);
    const { customerId } = campaignData;
    try {
      const imageTasks = Object.entries(pendingImageUpdates).map(async ([platform, image]) => {
        // Set required properties for the image upload
        image.originalname = `client-upload-${customerId}-${campaignId}-${platform}.png`;

        const res = await uploadImageAsset(token, customerId, image);

        if (res.ok) {
          const assetData = await res.json();
          updatedImageMapping[platform] = assetData.url;
        } else {
          // Throw an error if any of the API calls fail
          throw new Error(res.statusText);
        }
      });
      await Promise.all(imageTasks);
    } catch (err) {
      setError(err);
      console.error(err);

      // Abort if updating images fails
      setIsLoading(false);
      return;
    }

    // Map new image URLs to combined platform data
    Object.entries(updatedImageMapping).forEach(([platform, imgUrl]) => {
      newCombinedAppData[platform].mediaUrl = imgUrl;
    });

    // Calculate the updated ads
    const updatedAds = applyCombinedData(newCombinedAppData, loadedAds);

    let ret = null;

    // Update the ads
    try {
      const responses = await updateAds(token, updatedAds);

      // If any request fails, set the error flag
      const failedRequest = responses.find(resp => !resp.ok);

      if (failedRequest) {
        console.error(responses);
        setError("Failed to submit ad updates.");
      } else {
        ret = responses;
      }
    } catch (err) {
      console.error(err);
      setError("Failed to submit ad updates.");
    }

    setIsLoading(false);

    return ret;
  };

  // Add pending image update
  const addPendingImageUpdate = (platform, image) => {
    setPendingImageUpdates({ ...pendingImageUpdates, [platform]: image });
  };

  // Get a diff of changes
  const getUnsavedChanges = () => {
    const updatedAds = applyCombinedData(combinedAdData, loadedAds);

    return detailedDiff(loadedAds, updatedAds);
  };

  // Load ads data in background when switching between campaigns
  useEffect(() => {
    const fetch = async () => {
      // Reset state when campaignList or the selected campaign Id change
      setLoadedAds(null);
      setCombinedData(null);
      setError(null);

      if (!campaignList) return;
      const campaignData = campaignList.find(campaign => campaign._id === campaignId);

      // If no compaign matches, abort
      if (!campaignData) return;

      // If no ads are associated with the campaign, abort
      const adIds = campaignData.adIds;
      if (!adIds) return;

      // Load the associated ads
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const resp = await getAdsByCampaignId(token, campaignData._id);
      if (!resp.ok) {
        console.error(resp);
        setError(resp.statusText);
        setIsLoading(false);

        return; // Abort if the request fails
      }
      setIsLoading(false);

      // Set loaded ad data
      const loadedAds = await resp.json();
      setLoadedAds(loadedAds);

      // Calculate and set the combined ad data
      const newCombinedData = extractCombinedData(loadedAds);
      setCombinedData(newCombinedData);
    };

    fetch();
  }, [campaignId, campaignList, getAccessTokenSilently]);

  return (
    <EditorContext.Provider
      value={{
        commitChanges,
        getUnsavedChanges,
        combinedAdData,
        error,
        isLoading,
        setCombinedData,
        addPendingImageUpdate,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

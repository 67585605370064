import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { stringify } from "query-string";
import mapRuntimeEnvVar from "../mapRuntimeEnvVar";

const serviceMapping = {
  customer: mapRuntimeEnvVar("REACT_APP_API_BASE_URI_CUSTOMER"),
  campaign: mapRuntimeEnvVar("REACT_APP_API_BASE_URI_CAMPAIGN"),
  ad: mapRuntimeEnvVar("REACT_APP_API_BASE_URI_AD"),
  asset: mapRuntimeEnvVar("REACT_APP_API_BASE_URI_ASSET"),
  jobpost: mapRuntimeEnvVar("REACT_APP_API_BASE_URI_JOBPOST"),
  user: mapRuntimeEnvVar("REACT_APP_API_BASE_URI_USER"),
};

// Utility function to fetch data from a Connect service
const connectFetch = async (service, token, path, options, noContentType = false) => {
  const uri = `${serviceMapping[service]}${path}`;

  const requestOptions = options || {};
  requestOptions.headers = requestOptions.headers || {};
  requestOptions.headers["authorization"] = `Bearer ${token}`;

  // Omit cookies by default
  // In higher environments, the portal runs on the same domain as the API and other frontends that set large cookies
  // This can cause our API requests to fail with a HTTP 431 error
  requestOptions.credentials = requestOptions.credentials || "omit";

  // Set Content-Type as application/json by default unless noContentType is set
  if (!noContentType && !requestOptions.headers["Content-Type"])
    requestOptions.headers["Content-Type"] = "application/json";

  return await fetch(uri, requestOptions);
};

// Utility function that converts filtering, sorting and limiting options into a query
const convertQueryToUri = async (uri, { filter, page, pageSize, order }) => {
  const query = {
    filter: JSON.stringify(filter || {}),
    order: JSON.stringify(order || {}),
    page: page || 0,
    pageSize,
  };
  return `${uri}?${stringify(query)}`;
};

// Utility wrapper to transform API calls into React hooks
// The first argument of the caller function should be the user token
const connectApiWrapperHook = apiCaller => {
  return (...hookArgs) => {
    const { getAccessTokenSilently } = useAuth0();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);

    const startFetch = async (...fetchArgs) => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const resp = await apiCaller(token, ...hookArgs, ...fetchArgs);

        setResponse(resp);

        setIsLoading(false);
        return resp;
      } catch (err) {
        setError(err);

        setIsLoading(false);
        return err;
      }
    };

    return { fetchState: { isLoading, error, response }, startFetch };
  };
};

export { connectFetch, connectApiWrapperHook, convertQueryToUri };

import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getCreativesFromCampaign } from "../../../utils";

export default function CampaignShare({ selectedCampaignData }) {

  const prettifySourceText = (source, type) => {
    const typeString = type.split("-").length > 1 ? type.split("-")[1] : type.split("-")[0] 
    return `${source.charAt(0).toUpperCase()}${source.slice(1)} - ${typeString.charAt(0).toUpperCase()}${typeString.slice(1)}`;
  }

  const renderShareLink = (source, type, link) => {
    return (
      <CopyToClipboard text={link} key={link}>
        <button className="btn btn-block btn-sm btn-outline-secondary p-2 px-3 d-flex flex-row align-items-center text-left">
          <div className="pr-3">
            <ion-icon name={`logo-${source}`} style={{ marginLeft: "14.4px" }} />
          </div>
          <div>
            <strong>{prettifySourceText(source, type)}</strong>
            <br />
            <small>Click to copy link</small>
          </div>
        </button>
      </CopyToClipboard>
    );
  };

  if (!selectedCampaignData) return <div>No campaign selected.</div>;
  
  if (!selectedCampaignData.ads) return <div>The selected campaign has no ads to share.</div>;

  const creatives = getCreativesFromCampaign(selectedCampaignData);

  if (!creatives.length) {
    return <div>The selected campaign has no creatives to create Social Media posts with.</div>;
  }
  if (!Object.values(creatives).some((creative) => Boolean(creative.adUrl))) {
    return <div>The selected campaign has no ads with shareable links.</div>;
  }

  return (
    <div className="col-12">
      <p className="text-op-75 mb-5">Don't forget to share your ads in social media, the links below are shareable.</p>

      {creatives.map(creative => {
        return creative.adUrl ? renderShareLink(creative.name, creative.type, `${creative.adUrl}`) : ''
      })}
    </div>
  );
}

import { useEffect, useState } from "react";

interface AggregatedAnalytics {
  spend: number;
  impressions: number;
  reach: number;
  clicks: number;
  reactions: number;
  applicationsCompleted: number;
  [key: string]: number;
}

const UseAnalyticsHook = (ads: { analytics: string }[]): AggregatedAnalytics => {
  const defaultAnalytics: AggregatedAnalytics = {
    spend: 0,
    impressions: 0,
    reach: 0,
    clicks: 0,
    reactions: 0,
    applicationsCompleted: 0,
  };
  const [aggregatedAnalytics, setAggregatedAnalytics] = useState<AggregatedAnalytics>(defaultAnalytics);

  useEffect(() => {
    if (ads.length || []) {
      ads.forEach(ad => {
        const responseAnalytics: any = ad.analytics || {};
        Object.keys(defaultAnalytics).forEach(key => {
          defaultAnalytics[key] = (Number(responseAnalytics[key]) || 0) + defaultAnalytics[key];
        });
      });
      setAggregatedAnalytics(defaultAnalytics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ads]);

  return aggregatedAnalytics;
};

export default UseAnalyticsHook;

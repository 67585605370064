const runtimeEnvironmentMap = {
  development: {
    REACT_APP_API_BASE_URI_CUSTOMER: process.env.REACT_APP_API_BASE_URI_CUSTOMER,
    REACT_APP_API_BASE_URI_CAMPAIGN: process.env.REACT_APP_API_BASE_URI_CAMPAIGN,
    REACT_APP_API_BASE_URI_ASSET: process.env.REACT_APP_API_BASE_URI_ASSET,
    REACT_APP_API_BASE_URI_JOBPOST: process.env.REACT_APP_API_BASE_URI_JOBPOST,
    REACT_APP_API_BASE_URI_AD: process.env.REACT_APP_API_BASE_URI_AD,
    REACT_APP_API_BASE_URI_USER: process.env.REACT_APP_API_BASE_URI_USER,
    REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_RECRUITERS_URI: process.env.REACT_APP_RECRUITERS_URI,
    REACT_APP_ADMIN_URI: process.env.REACT_APP_ADMIN_URI,
  },
  demo: {
    REACT_APP_API_BASE_URI_CUSTOMER: "https://connect-demo.adway.ai/api/customers",
    REACT_APP_API_BASE_URI_CAMPAIGN: "https://connect-demo.adway.ai/api/campaigns",
    REACT_APP_API_BASE_URI_ASSET: "https://connect-demo.adway.ai/api/assets",
    REACT_APP_API_BASE_URI_JOBPOST: "https://connect-demo.adway.ai/api/jobposts",
    REACT_APP_API_BASE_URI_AD: "https://connect-demo.adway.ai/api/campaignads",
    REACT_APP_API_BASE_URI_USER: "https://connect-demo.adway.ai/api/users",
    REACT_APP_AUTH0_DOMAIN: "adway-connect-demo.eu.auth0.com",
    REACT_APP_AUTH0_AUDIENCE: "connect-demo.adway.ai/api",
    REACT_APP_AUTH0_CLIENT_ID: "WAOq5nc2zdZJ28tdS8bFSZ9LMpUGjq3U",
    REACT_APP_RECRUITERS_URI: "https://connect-demo.adway.ai/recruiter",
    REACT_APP_ADMIN_URI: "https://connect-demo.adway.ai/admin",
  },
  test: {
    REACT_APP_API_BASE_URI_CUSTOMER: "https://connect-test.adway.ai/api/customers",
    REACT_APP_API_BASE_URI_CAMPAIGN: "https://connect-test.adway.ai/api/campaigns",
    REACT_APP_API_BASE_URI_ASSET: "https://connect-test.adway.ai/api/assets",
    REACT_APP_API_BASE_URI_JOBPOST: "https://connect-test.adway.ai/api/jobposts",
    REACT_APP_API_BASE_URI_AD: "https://connect-test.adway.ai/api/campaignads",
    REACT_APP_API_BASE_URI_USER: "https://connect-test.adway.ai/api/users",
    REACT_APP_AUTH0_DOMAIN: "adway-connect-test.eu.auth0.com",
    REACT_APP_AUTH0_AUDIENCE: "connect-test.adway.ai/api",
    REACT_APP_AUTH0_CLIENT_ID: "1za9pXagT4J1nMkkgRaSCVhupkLnSjQD",
    REACT_APP_RECRUITERS_URI: "https://connect-test.adway.ai/recruiter",
    REACT_APP_ADMIN_URI: "https://connect-test.adway.ai/admin",
  },
  staging: {
    REACT_APP_API_BASE_URI_CUSTOMER: "https://connect-staging.adway.ai/api/customers",
    REACT_APP_API_BASE_URI_CAMPAIGN: "https://connect-staging.adway.ai/api/campaigns",
    REACT_APP_API_BASE_URI_ASSET: "https://connect-staging.adway.ai/api/assets",
    REACT_APP_API_BASE_URI_JOBPOST: "https://connect-staging.adway.ai/api/jobposts",
    REACT_APP_API_BASE_URI_AD: "https://connect-staging.adway.ai/api/campaignads",
    REACT_APP_API_BASE_URI_USER: "https://connect-staging.adway.ai/api/users",
    REACT_APP_AUTH0_DOMAIN: "adway-connect-staging.eu.auth0.com",
    REACT_APP_AUTH0_AUDIENCE: "connect-staging.adway.ai/api",
    REACT_APP_AUTH0_CLIENT_ID: "5qNa3GMvKjO7zDGGVXDpKbv4wACUtlGD",
    REACT_APP_RECRUITERS_URI: "https://connect-staging.adway.ai/recruiter",
    REACT_APP_ADMIN_URI: "https://connect-staging.adway.ai/admin",
  },
  production: {
    REACT_APP_API_BASE_URI_CUSTOMER: "https://connect.adway.ai/api/customers",
    REACT_APP_API_BASE_URI_CAMPAIGN: "https://connect.adway.ai/api/campaigns",
    REACT_APP_API_BASE_URI_ASSET: "https://connect.adway.ai/api/assets",
    REACT_APP_API_BASE_URI_JOBPOST: "https://connect.adway.ai/api/jobposts",
    REACT_APP_API_BASE_URI_AD: "https://connect.adway.ai/api/campaignads",
    REACT_APP_API_BASE_URI_USER: "https://connect.adway.ai/api/users",
    REACT_APP_AUTH0_DOMAIN: "adway-connect.eu.auth0.com",
    REACT_APP_AUTH0_AUDIENCE: "connect.adway.ai/api",
    REACT_APP_AUTH0_CLIENT_ID: "fSI6MXnh2u4SfGbDKmSuyqrepvsgCXEb",
    REACT_APP_RECRUITERS_URI: "https://connect.adway.ai/recruiter",
    REACT_APP_ADMIN_URI: "https://connect.adway.ai/admin",
  },
};

const mapRuntimeEnvVar = name => {
  const value =
    process.env.NODE_ENV === "development"
      ? runtimeEnvironmentMap.development[name]
      : runtimeEnvironmentMap[process.env.REACT_APP_TARGET][name];

  if (!value) {
    throw new Error(
      `The value of ${name} cannot be mapped for NODE_ENV="${process.env.NODE_ENV}" and BUILD_TARGET="${process.env.REACT_APP_TARGET}"`
    );
  }

  return value;
};

export default mapRuntimeEnvVar;

import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, Input } from "reactstrap";
import ImageSelector from "./imageSelector";

import { EditorContext } from "../../../contexts/editorContext";
import { set } from "lodash";

const spanStyle = {
  display: "flex",
  alignItems: "center",
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineheight: "17px",
  letterSpacing: "0.04em",
  color: "#6C757D",
  margin: "20px 0",
  padding: "0px",
  textTransform: "capitalize",
};

const buttonStyle = {
  width: "146px",
  height: "38px",
  padding: "0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "50px",
};

const errorIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C15.53 0 20 4.47 20 10C20 15.53 15.53 20 10 20C4.47 20 0 15.53 0 10C0 4.47 4.47 0 10 0ZM13.59 5L10 8.59L6.41 5L5 6.41L8.59 10L5 13.59L6.41 15L10 11.41L13.59 15L15 13.59L11.41 10L15 6.41L13.59 5Z"
      fill="white"
    />
  </svg>
);

const MAX_AD_BODY_SYMBOLS = 600;

export default function EditorForm({ selectedCampaignId }) {
  const { commitChanges, combinedAdData, isLoading, error, setCombinedData, addPendingImageUpdate } = useContext(
    EditorContext
  );

  // Notification state
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  // If the context has an error, display it.
  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  if (!combinedAdData) return null;

  // Apply the changes
  const onUpdateClick = async () => {
    const ret = await commitChanges();

    if (ret) {
      setWarningMessage(null);
      setSuccessMessage("Ad update has been successfully queued.");
    }
  };

  // Submit the change to context (conditionally)
  const handleChange = (property, event) => {
    const value = event.currentTarget.value;

    // Cap the amount of characters for ad body
    if (property.includes(".body") && value.length > MAX_AD_BODY_SYMBOLS) {
      return;
    }

    setCombinedData({ ...set(combinedAdData, property, value) });
    setWarningMessage("You have unsaved changes.");
  };

  // Render a text box for editing platform texts
  const renderPlatformBody = platform => {
    if (!combinedAdData[platform]) return null;

    const charaterUsageText = `(${combinedAdData[platform].body.length} out of ${MAX_AD_BODY_SYMBOLS} characters used)`;

    return (
      <FormGroup>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span style={spanStyle}>{`${platform} Ad Text ${charaterUsageText}`}</span>
        </div>

        <Input
          style={{ height: "125px", backgroundColor: "white" }}
          sm={20}
          type="textarea"
          name={`${platform}Body`}
          id={`${platform}Body`}
          value={combinedAdData[platform].body}
          onChange={e => handleChange(`${platform}.body`, e)}
        />
      </FormGroup>
    );
  };

  // Render an image uploader for platform
  const renderImageSelector = platform => {
    if (!combinedAdData[platform]) return null;

    return (
      <ImageSelector
        imageUrl={combinedAdData[platform].mediaUrl}
        platform={platform}
        onError={err => setErrorMessage(err)}
        onNewImage={imageData => {
          setWarningMessage("You have unsaved changes.");

          // Add the new image to the update queue
          // NOTE: We DO NOT upload the images before the "Update Ad" is clicked
          addPendingImageUpdate(platform, imageData);
        }}
      />
    );
  };

  // Render a notification regarding a success or an error
  const renderNotification = () => {
    // Notiifcation display priority
    const notification = errorMessage || warningMessage || successMessage;
    const color = errorMessage ? "#F86969" : warningMessage ? "#FF47BD" : "#8AC379";

    if (!notification) return null;

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          padding: "15px 30px",
          color: "white",
          backgroundColor: color,
          borderRadius: "6px",
        }}
      >
        {errorMessage && errorIcon}
        <span style={{ marginLeft: "20px" }}>{notification.toString()}</span>
      </div>
    );
  };

  return (
    <Col
      style={{
        width: "100%x",
        background: "rgba(15, 0, 61, 0.03)",
        borderRadius: "5px",
      }}
    >
      <div style={{ padding: "25px" }}>
        <Link to={`/jobs/${selectedCampaignId}/`}>
          <h6
            style={{
              justifyContent: "flex-end",
              display: "flex",
              color: "#999",
            }}
          >
            Close
          </h6>
        </Link>
        <Form>
          <h4 style={{ color: "black" }}>Update Ad</h4>

          <span
            style={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "17px",
              letterSpacing: "0.04em",
              color: "#6C757D",
            }}
          >
            Update your job ad in social media.
          </span>

          <br />

          <FormGroup>
            <span style={spanStyle}>Ad Title</span>

            <Input
              style={{ height: "50px", backgroundColor: "white" }}
              type="text"
              name="title"
              id="title"
              value={combinedAdData.title}
              onChange={e => handleChange("title", e)}
            />
          </FormGroup>

          <br />

          {renderPlatformBody("facebook")}
          {renderPlatformBody("instagram")}
          {renderPlatformBody("linkedin")}
          {renderPlatformBody("snapchat")}

          {renderImageSelector("facebook")}
          {renderImageSelector("instagram")}
          {renderImageSelector("linkedin")}
          {renderImageSelector("snapchat")}
        </Form>

        {renderNotification()}

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {isLoading && (
            <div style={buttonStyle}>
              <span className="spinner" style={{ margin: "auto" }} />
            </div>
          )}
          {!isLoading && (
            <button className={`btn btn-lg btn-secondary`} onClick={onUpdateClick} style={buttonStyle}>
              Update Ad
            </button>
          )}
        </div>
      </div>
    </Col>
  );
}

import React from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorPage from ".";

const AuthZeroErrorPage = () => {
  const { logout } = useAuth0();
  return (
    <Route
      path="/"
      render={() => (
        <ErrorPage
          readableError={"Your user account has not been setup. Please contact our support team at:"}
          handleClose={logout}
        >
          <a
            style={{ margin: "0 4rem 4rem 4rem", textAlign: "center", color: "#BB6BD9", textDecoration: "underline" }}
            href="mailto: support@adway.ai"
          >
            support@adway.ai
          </a>
        </ErrorPage>
      )}
    />
  );
};

export default AuthZeroErrorPage;

import React from "react";
import { useParams } from "react-router-dom";
import { EditorContextProvider } from "../../../contexts/editorContext";
import EditorForm from "./edit";

export default function AdEditor() {
  const { campaignID } = useParams();

  if (!campaignID) return null;

  return (
    <div className="col-7 bg-white p-5 scroll-y" style={{ maxHeight: "100%" }}>
      <EditorContextProvider>
        <EditorForm selectedCampaignId={campaignID} />
      </EditorContextProvider>
    </div>
  );
}

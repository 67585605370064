import React, { useContext } from "react";

import Listing from "../../components/listing";
import Menu from "../menu";

import { CampaignsContext } from "../../../contexts/campaignsContext";

// After what point to trigger extra campaign loding
const SCROLL_PROGRESS_TRIGGER_FOR_LOADING = 0.8;

// Handle infinite scroll
const handleScroll = (e, loadMoreCampaigns, campaignsState) => {
  const target = e.target;
  const maxPos = target.scrollHeight - target.clientHeight;
  const currPos = target.scrollTop;

  // Calculate the scroll progress
  // Once it passes a threshold, load additonal campaigns
  // Unless we're already loading data
  if (currPos / maxPos > SCROLL_PROGRESS_TRIGGER_FOR_LOADING && campaignsState !== "loading") {
    // Load remaining campaigns
    loadMoreCampaigns(false);
  }
};

export default function CampaignDisplay() {
  const { campaignList, loadMoreCampaigns, campaignsState } = useContext(CampaignsContext);

  return (
    <div
      className="col-7 bg-white  py-4 px-5 scroll-y"
      style={{ maxHeight: "100%" }}
      onScroll={e => handleScroll(e, loadMoreCampaigns, campaignsState)}
    >
      <Menu />

      <Listing list={campaignList} isLoading={campaignsState === "loading"} />
    </div>
  );
}

import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  selected: boolean;
  onClickHandler?: () => void;
  platform?: string;
}

const PreviewSelector: FC<Props> = ({ selected, onClickHandler, platform, children }) => {
  return (
    <Container selected={selected} className="tab-item text-center active" onClick={onClickHandler}>
      {selected && <ActiveIndicator />}
      <InnerContainer>
        {children}
        <Text>{platform} Preview</Text>
      </InnerContainer>
    </Container>
  );
};

const Container = styled("div")<Props>`
  width: 120px;
  height: 120px;
  background: ${selected => (selected ? "rgba(255, 255, 255, 0.20)" : "rgba(255, 255, 255, 0.1)")};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  cursor: pointer;
  margin: 0 4px;
  position: relative;
`;

const ActiveIndicator = styled("div")`
  position: absolute;
  height: 6px;
  width: 120px;
  top: 0;
  left: 0;
  background: linear-gradient(269.91deg, #ff47bd 1.15%, #b969ff 100%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
`;

const InnerContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Text = styled("span")`
  width: 110px;
  height: 30px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  fontsize: 15px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.04em;
  padding-top: 10px;
  color: ${selected => (selected ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.6)")};
  opacity: ${selected => (selected ? "1" : "0.8")};
`;

export default PreviewSelector;

import React from "react";

import Dropzone from "react-dropzone";

// Button for selecting images from user's computer
export const ImageSelectButton = ({ onDrop }) => {
  return (
    <div style={{ display: "flex" }}>
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <button
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              padding: "10px 16px",
              fontWeight: "bolder",
              marginRight: "6px",
              marginLeft: "6px",
            }}
            type="button"
            onClick={async e => {
              e.preventDefault();
            }}
            className={`btn btn-sm btn-primary`}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            Select Image
            <svg
              style={{ paddingLeft: "5px" }}
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M18.9 9.84141C19.0441 9.46524 19.125 9.05391 19.125 8.625C19.125 6.76172 17.6133 5.25 15.75 5.25C15.0574 5.25 14.4105 5.46094 13.8762 5.81953C12.9023 4.13203 11.0848 3 9 3C5.89219 3 3.375 5.51719 3.375 8.625C3.375 8.71992 3.37852 8.81484 3.38203 8.90977C1.41328 9.60234 0 11.4797 0 13.6875C0 16.4824 2.26758 18.75 5.0625 18.75H18C20.4855 18.75 22.5 16.7355 22.5 14.25C22.5 12.0738 20.9531 10.2563 18.9 9.84141ZM13.8305 12H11.5313V15.9375C11.5313 16.2469 11.2781 16.5 10.9688 16.5H9.28125C8.97188 16.5 8.71875 16.2469 8.71875 15.9375V12H6.41953C5.9168 12 5.66719 11.3953 6.02227 11.0402L9.72774 7.33477C9.9457 7.1168 10.3043 7.1168 10.5223 7.33477L14.2277 11.0402C14.5828 11.3953 14.3297 12 13.8305 12Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="22" height="22" fill="white" transform="matrix(1 0 0 -1 0 22)" />
                </clipPath>
              </defs>
            </svg>
          </button>
        )}
      </Dropzone>
    </div>
  );
};

// Button for saving the applied new image/crop changes
export const ImageSaveButton = ({ isDisabled, onClick }) => {
  const disabledStyles = {
    backgroundColor: "#b7b3c5",
    borderColor: "#b7b3c5",
  };

  const baseStyles = {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    padding: "10px 16px",
    fontWeight: "bolder",
    marginRight: "6px",
    marginLeft: "6px",
  };

  const buttonStyle = isDisabled ? { ...baseStyles, ...disabledStyles } : baseStyles;

  return (
    <div style={{ display: "flex" }}>
      <button
        style={buttonStyle}
        type="button"
        onClick={onClick}
        disabled={isDisabled}
        className={`btn btn-sm btn-primary`}
      >
        Save changes
      </button>
    </div>
  );
};

export default ImageSelectButton;

import Color from "color";

export const getTotalAudienceSize = (audiences: Record<string, number>): number => {
  return Object.values(audiences).reduce((prev, curr) => {
    return prev + curr;
  }, 0);
};

export const getColor = (id: number) => {
  const baseColors = ["#F1416C", "#165DFF", "#ED6B31", "#50CD89", "#D91AD9", "#FFC700", "#7239EA", "#009EF7"];
  const colorList: string[] = [...baseColors];
  baseColors.forEach(color => {
    colorList.push(Color(color).lighten(0.15).toString());
  });
  baseColors.forEach(color => {
    colorList.push(Color(color).lighten(0.25).toString());
  });

  return colorList[id];
};

export const calculateAudienceUnder1000 = (facebookAudienceSize: number, audiences: Record<string, number>): number => {
  if (!facebookAudienceSize) return 0;

  const sumAbove1000 = Object.values(audiences)
    .filter(x => x > 1000)
    .reduce((prev, curr) => {
      return prev + curr;
    }, 0);

  const result = facebookAudienceSize - sumAbove1000;

  return result < 0 ? 0 : result;
};

export const separateThousands = (arg: number, format = true) => {
  if (!arg || arg === 0) {
    return `00`;
  }

  if (arg > 9999 && format) {
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1,
    }).format(arg);
  }
  return arg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { getAdsByCampaignId } from "./ads";
import { getJobPostById } from "./jobPost";
import { connectFetch, connectApiWrapperHook, convertQueryToUri } from "./utils";

const getCampaignFeed = async (token, page, pageSize, filter, order) => {
  const uri = await convertQueryToUri("/", {
    filter: filter,
    page,
    pageSize,
    order,
  });

  return connectFetch("campaign", token, uri)
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .then(data => {
      return Promise.all(
        data.filter(campaign => !!campaign.jobPostId).map(decorateCampaignWithAds(token))
      );
    })
    .then(async data => {
        const decoratedCampaigns = await Promise.all(data.map(decorateCampaignWithAtsJobPostId(token)));
        return { ok: true, data: decoratedCampaigns };
    });
};

const getFilters = async (token, customerId) => {
  return await connectFetch("campaign", token, `/customers/${customerId}/filters`);
};

const removeCampaign = async (token, campaignId) => {
  const uri = `/${campaignId}`;
  return await connectFetch("campaign", token, uri, { method: "DELETE" });
};

const decorateCampaignWithAds = token => async campaign => {
  const ads = await getAdsByCampaignId(token, campaign._id).then(res => res.json());
  campaign.ads = ads;
  return campaign;
};

const decorateCampaignWithAtsJobPostId = token => async campaign => {
  const jobPost = await getJobPostById(token, campaign.jobPostId).then(res => res.json());
  campaign.atsJobPostId = jobPost.atsJobPostId;
  return campaign;
};

const executeCampaignAction = async (token, campaignId, actionName) => {
  const actionUriSegment = actionName === "publish" ? "activate" : actionName;
  const uri = `/${campaignId}/${actionUriSegment}`;
  return await connectFetch("campaign", token, uri, { method: "POST" });
};

const getCampaignById = async (token, campaignId) => {
  const uri = `/${campaignId}`;
  return await connectFetch("campaign", token, uri);
};

export const useCustomerCampaignCount = customerId => {
  const { getAccessTokenSilently } = useAuth0();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    const fetchCustomer = async customerId => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const res = await connectFetch("campaign", token, `/customers/${customerId}/status/count`);
        if (res.ok) {
          const customerCampaignCount = await res.json();
          setData(customerCampaignCount);
        }
      } catch (error) {
        setError(error.errorMessage);
      } finally {
        setLoading(false);
      }
    };

    if (customerId) {
      fetchCustomer(customerId);
    }
  }, [customerId, getAccessTokenSilently]);

  return {
    error,
    loading,
    runningAds: (data && data.running) || 0,
    scheduledAds: (data && data.scheduled) || 0,
  };
};

const useCampaignFeed = connectApiWrapperHook(getCampaignFeed);
const useRemoveCampaign = connectApiWrapperHook(removeCampaign);
const useExecuteCampaignAction = connectApiWrapperHook(executeCampaignAction);

export { useCampaignFeed, getCampaignFeed, useRemoveCampaign, useExecuteCampaignAction, getCampaignById, getFilters };
